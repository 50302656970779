/**
 * Check if input is null or undefined
 * 
 * @param value input value to check
 * @returns boolean
 */
export const isNullOrUndefined = (value: any): boolean => {
  return value === null || value === undefined;
};

/**
 * Check if input list has any elements
 *  
 * @param value input list to check
 * @returns boolean
 */
export const hasElements = (list: any[]): boolean => {
  return !hasNoElements(list);
}

/**
 * Check if input list has no elements
 *  
 * @param value input list to check
 * @returns boolean
 */
export const hasNoElements = (list: any[]): boolean => {
  return isNullOrUndefined(list) || list.length === 0;
}
