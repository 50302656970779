export default {
  index: {
    vidFname: "log-and-solve-comp-en.mp4",
    topNav: {
      item1: "Vorteile",
      item2: "Kontakt",
      item3: "Dashboard",
      item4: "Einloggen",
    },
    hero: {
      title: "BESSER KULTIVIEREN MIT DATEN",
      intro:
        "<p>Wir helfen Gärtnern, kulturrelevante Daten zu sammeln und auszuwerten. Diese Daten unterstützen Sie bei täglichen Entscheidungen und bei der Vorbeugung von Kulturproblemen.</p>",
      email: "Kontaktieren Sie uns, um Log & Solve zu nutzen",
    },
    chats: [
      {
        title: "Vergleiche Fotos",
        content: "Wie entwickelt sich die Kultur im Vergleich zum Vorjahr?",
      },
      {
        title: "Expertenbewertung Ihrer Probenergebnisse",
        content: "Direktes und individuelles Feedback durch technische Berater",
      },
      {
        title: "Geben Sie weniger Wasser",
        content: "Das Substrat ist zu feucht",
      },
      {
        title: "Erhöhen Sie den EC-Wert",
        content: "Nährstoffgehalt ist zu niedrig",
      },
    ],
    cards: [
      {
        title: "Ausfallraten minimieren",
        content:
          "Die digitale Auswertung verschiedener Kulturparameter kann dabei helfen Wachstumsdefizite zu erklären. Die Daten helfen Ihnen dabei, Kulturprobleme zu vermeiden und Erträge zu steigern.",
      },
      {
        title: "Direkte Kommunikation mit Beratern",
        content:
          "Digitale Kulturdaten können Sie schnell und einfach mit Beratern teilen. Sie bestimmen, wer Einblick in Ihre Daten hat, und können sich schnell und kompetent beraten lassen.",
      },
      {
        title: "Erfassen Sie Ihr Kulturwissen und Ihren Unternehmenswert",
        content:
          "Ganz gleich, ob Sie Ihr Unternehmen verkaufen wollen oder die Übergabe an die nächste Generation vorbereiten, durch die archivierten Kulturdaten können Sie Produktionsprozesse und auch den Wert Ihres Unternehmens transparenter darlegen.",
      },
      {
        title: "Schnelle und einfache Zertifizierung",
        content:
          "Erfassen Sie die verwendeten Düngemittel, Pflanzenschutzmittel und mehr. Log & Solve unterstützt Sie bei der Datenerfassung, um mehr Transparenz in der Kommunikation mit Ihren Kunden zu ermöglichen.",
      },
    ],
    companyPitch: {
      content:
        "Durch das Sammeln kulturrelevanter Daten gewinnen Sie noch mehr Einblick in Ihren Anbauprozess. Die kontinuierliche Erfassung von Notizen, Fotos, Analysenergebnissen zu Wasser- und Substratproben sowie weiterer relevanter Daten unterstützt Sie dabei, den aktuellen Entwicklungsstand Ihrer Kulturen abzubilden und zu vergleichen. Dies ermöglicht eine bessere Beratung und schnellere Problemlösung während der Kultur. Ziel von Log & Solve ist es, Kulturausfälle, Krankheiten und andere Herausforderungen während der Kulturzeit zu vermeiden. Um dies zu erreichen, kombinieren wir intelligente Software mit dem Wissen erfahrener technischer Berater.",
      smallText: "Ermöglicht durch Klasmann-Deilmann",
    },
    contact: {
      title: "Kontakt",
      intro:
        "Sind Sie daran interessiert, mehr über die Verwendung von Log & Solve zu erfahren? Ihr Klasmann-Deilmann-Vertriebsmitarbeiter kann Ihnen mehr über die Vorteile für Ihre Gärtnerei sagen. Sie können sich auch direkt über",
      buttonText: "Schreiben Sie uns",
      persons: [
        {
          name: "Cedric Saborowski",
          email: "log.solve@klasmann-deilmann.com",
          phoneNumber: "tel: +49.17.15.56.32.40",
        },
      ],
    },
    faq: {
      titleOne: "Dateneigentum, GDPR und Sicherheit",
      itemsOne: [
        {
          title:
            "Werde ich die Kontrolle über mein Dashboard und meine Daten haben?",
          content:
            "Als Eigentümer Ihrer Daten können Sie diese jederzeit kontrollieren, verwalten oder löschen. Unser Service entspricht der GDPR, der allgemeinen Datenschutzverordnung der Europäischen Union.",
        },
        {
          title: "Was ist die GDPR?",
          content:
            "Die Allgemeine Datenschutzverordnung (GPDR) ist ein Gesetz der Europäischen Union, das am 25. Mai 2018 verabschiedet wurde und Organisationen verpflichtet, persönliche Daten zu schützen und die Rechte aller Personen auf dem Gebiet der EU zu wahren. Die Verordnung umfasst sieben Datenschutzprinzipien und acht Datenschutzgesetze, die umgesetzt werden müssen. Die GPDR ermächtigt die Datenschutzbehörden, die Datenschutzverordnung mit Sanktionen und Bußgeldern durchzusetzen.",
        },
        {
          title: "Wer ist Eigentümer meiner Daten?",
          content:
            "Als Nutzer des Log & Solve-Dashboards sind Sie immer Eigentümer Ihrer Daten. Sie können sich darauf verlassen, dass Ihre Daten streng vertraulich behandelt und nicht ohne Ihre ausdrückliche Zustimmung an Dritte weitergegeben werden. Log & Solve ist ein Service und Eigentum von Klasmann-Deilmann.",
        },
        {
          title:
            "Für welche Zwecke wird Klasmann-Deilmann Ihre Daten verwenden?",
          content:
            "Um Ihnen den Log & Solve-Dienst anzubieten, Um Sie mit maßgeschneideter Beratung zu unterstützen, kann das Log & Solve-Team auf Ihre Daten zugreifen und eine statistische Auswertung erstellen, Um automatisierte, zeitnahe Hinweise zu Ihren Kulturen zu versenden, Um anonymisierte statistische Analysen durchzuführen und dadurch unsere Dienstleistungen stetig zu verbessern, und Zur Anpassung und Verbesserung der Datenschutzmaßnahmen.",
        },
        {
          title:
            "Werden meine früheren Kulturdaten in meinem Dashboard gespeichert?",
          content:
            "Ja, Ihre bisherigen Daten bleiben sicher in Ihrem Dashboard. Sie können separate Datenblätter für einzelne Kulturen und Chargen in Ihrem Dashboard erstellen, die für den Zugriff auch in Zukunft, z. B. für Vergleiche verschiedener Chargen aus verschiedenen Jahren, verfügbar sind.",
        },
        {
          title: "Sind meine Kulturdaten sicher gespeichert?",
          content:
            "Log & Solve erfüllt hohe Sicherheitsstandards, die allgemein akzeptiert sind. Log & Solve wird bei Amazon AWS gehostet, einer der angesehensten und sichersten Cloud-Hosting-Lösungen auf dem Markt. Unsere Server werden hinsichtlich verdächtiger Aktivitäten überwacht und die aktuellsten Sicherheitsupdates regelmäßig eingespielt.",
        },
      ],
      titleTwo: "Kosten",
      itemsTwo: [
        {
          title:
            "Kann ich das Log & Solve-Dashboard mit einem kostenlosen Testkonto ausprobieren?",
          content:
            "Ja, Sie können Log & Solve für eine Testperiode von zwei Monaten kostenlos nutzen.",
        },
        {
          title:
            "Was kostet die regelmäßige Nutzung des Log & Solve-Dashboards?",
          content:
            "Das Log & Solve-Dashboard ist in verschiedenen monatlichen Abonnements mit unterschiedlichen Leistungen erhältlich. Das Basis-Abonnement ermöglicht die Nutzung des Log & Solve-Dashboards mit den Log-Funktionalitäten für Ihre verschiedenen Kulturen und unterschiedlichen Chargen.",
        },
        {
          title:
            "Neben dem Grundabonnement bietet Log & Solve verschiedene Add-Ons, die auf Ihre Bedürfnisse und Ihr Kultursystem zugeschnitten werden können:",
          content:
            "Substrat-Probenahme-Service, Sensorsysteme (z.B. Substratfeuchte, Substrattemperatur, Lufttemperatur, Strahlung usw.) und Technische Expertise",
        },
        {
          title:
            "Bitte nehmen Sie Kontakt mit uns auf, damit wir Ihnen ein maßgeschneidertes Angebot unterbreiten können.",
          content: "",
        },
      ],
      titleThree: "Funktionen von Log & Solve",
      itemsThree: [
        {
          title: "Kann ich Daten für jede Charge erfassen?",
          content:
            "Ja, Sie können Daten wie Notizen, Bilder und Analysenergebnisse je Charge und pro Kultur protokollieren. Das Log & Solve Team kann Sie bei Bedarf dabei unterstützen, Datenseiten für neue Chargen und Kulturen in Ihrem Dashboard zu erstellen. Ihre Daten zu früheren Kulturen bleiben weiterhin verfügbar.",
        },
        {
          title:
            "Wie kann ich Laboranalysen (Substrat- und Wasseranalysen) meinem Dashboard hinzufügen?",
          content:
            "Alle Analysen werden vom Log & Solve Team automatisch auf Ihr Dashboard hochgeladen. Die Labore, mit denen wir für Log & Solve zusammenarbeiten, liefern die Daten in einem geeigneten Format für den automatischen Upload.",
        },
        {
          title:
            "Kann ich Log & Solve auf meinen mobilen Geräten (Smartphone, Tablet, etc.) verwenden)?",
          content:
            "Ja, Sie können Log & Solve auf Ihren mobilen Geräten verwenden. Das Dashboard und alle seine Funktionen sind mit Smartphones und Tablets vollständig zugänglich. Es ist möglich, dem Dashboard Fotos aus Ihrer Fotogalerie hinzuzufügen oder Bilder mit der Kamera Ihres Mobilgeräts direkt im Dashboard aufzunehmen sowie Notizen mit Ihren mobilen Geräten hochzuladen.",
        },
        {
          title:
            "Wie kann Klasmann-Deilmann mich durch Log & Solve unterstützen?",
          content:
            "Klasmann-Deilmann unterstützt Ihre Kulturführung mit digitaler Expertise zu Substrat- und Wasseranalysen in Kombination mit anderen verfügbaren Daten wie Fotos, Wetteraufzeichnungen, Sensordaten und Notizen, um den aktuellen Kulturzustand beurteilen zu können. Log & Solve ist ein zusätzlicher Service von Klasmann-Deilmann, der die Dienstleistung, die Sie aktuell von Klasmann-Deilmann erhalten, weder ersetzt noch verändert.",
        },
      ],
    },
    copyrightText:
      "Log & Solve ist ein digitaler Service von Klasmann-Deilmann",
  },
  docs: { title: "Documents" },
  dashboard: {
    title: "Kulturdaten",
    components: {
      mainNavigation: [
        { text: "Kulturdaten" },
        { text: "Dokumente" },
        { text: "My tasks" },
        { text: "Profileinstellungen" },
        { text: "Sensoren" },
        { text: "Klasmann-Deilmann webshop" },
        { text: "Kulturübersicht" },
        { text: "FAQ" },
        { text: "Menu" },
        { text: "Kultur hinzufügen" },
        { text: "Betriebsverwaltung" },
        { text: "Ausloggen" },
        { text: "Notiz" },
        { text: "Administrator" },
        { text: "Firma wechseln" },
        { text: "Protocols" },
        { text: "Daily summary" },
      ],
      cropNavigation: {
        showMoretext: "Weitere Kulturen",
        items: [
          { id: "log", text: "Log", slug: "", icon: "clipboard-list" },
          {
            id: "labAnalyses",
            text: "Lab analyses",
            slug: "lab",
            icon: "flask",
          },
          { id: "photos", text: "Foto's", slug: "photos", icon: "image" },
          { id: "profile", text: "Kulturprofil", slug: "edit", icon: "edit" },
          {
            id: "qrCode",
            text: "Download QR-code",
            slug: "pdf",
            icon: "file-pdf",
          },
          {
            id: "protocol",
            text: "Protocol",
            slug: "protocol",
            icon: "scroll",
          },
        ],
      },
      labDataChart: {
        lineDescription: "Optimum",
        selectMetrics: "Select metrics",
        pickMetrics: "Pick metrics",
        pickWeather: "Pick weather",
        ecNutrientContentConverted:
          "Nutrient content is converted to the reference EC.",
        units: {
          length: "Länge",
          diameter: "Durchmesser",
          ec: "ec",
          ph: "ph",
          weight: "Gewicht",
          yield: "Yield",
          numberOfBuds: "Anzahl Knospen",
          substrate: "Substrat",
          drain: "Abfluss",
          gift: "Gift",
          ditch: "Ditch",
        },
      },
      composition: { title: "Komposition" },
      ratio: { title: "Verhältnis" },
      ec: { title: "EC-Wert" },
      ph: { title: "pH-Wert" },
      analysis: {
        title: "Analyseergebnisse",
        labDataTable: {
          labId: "Lab id",
          date: "Date",
          export: "Exportieren",
          edit: "Bearbeiten",
          delete: "Löschen",
          addNote: "Add note",
          deleteNote: "Notiz löschen:",
          cancel: "Abbrechen",
          addNoteHeader: "Notiz zum Beispiel von hinzufügen",
          sampleDescription: "Musterbeschreibung",
          table: {
            titleLeft: "Hauptnährstoffe in mmol/l",
            titleCenter: "Spurenelemente in mmol/l",
            titleRight: "",
            titleFirstColumn: "Datum der Probenahme",
            titleUnit: "Unit",
          },
          tableLufa: {
            titleLeft: "Hauptnährstoffe in mg/l",
            titleCenter: "Spurenelemente in mg/l",
            titleRight: "",
            titleFirstColumn: "Datum der Probenahme",
            titleUnit: "Unit",
          },
          legend: {
            title: "Legende",
            description:
              "Bewertung erfolgt auf Basis der ’Bemestings Advies Basis’ (Niederländisches Düngeempfehlungssystem)",
            ranks: [
              { title: "zu hoch", subTitle: "Abweichung höher als 2" },
              { title: "hoch", subTitle: "Abweichung um 1 oder höher" },
              { title: "niedrig", subTitle: "Abweichung um -1 oder niedriger" },
              { title: "zu niedrig", subTitle: "Abweichung niedriger als -2" },
            ],
          },
          settings: { title: "Settings", displayUnit: "Display unit" },
        },
      },
      photos: {
        title: "Fotos aus der Kultur",
        buttons: {
          remove: { value: "Entfernen", singular: "Foto", plural: "Fotos" },
          edit: { value: "Bearbeiten" },
          view: { value: "Bearbeitung abbrechen" },
        },
        options: {
          all: { label: "Alle Fotos" },
          overview: { label: "Übersicht" },
          single: { label: "Einzelpflanze" },
          detail: { label: "Detail" },
          roots: { label: "Wurzeln" },
        },
      },
      uploadPhoto: {
        bigTitle: "Fotos aus der Kultur",
        subTitle: "Details (Foto):",
        caption: "Bildbeschriftung",
        firstOption: "Wähle ein Foto",
        secondOption: "Art des Fotos",
        success: "Erfolgreich hochgeladen!",
        options: {
          overview: { value: "Übersicht" },
          single: { value: "Einzelpflanze" },
          detail: { value: "Detail" },
          roots: { value: "Wurzeln" },
        },
        thirdOption: "Datum",
        button: "Speichern",
      },
      cropProfile: {
        bigTitle: "Kulturprofil",
        firstCategory: {
          title: "Kulturprofil",
          cultivation: {
            text: "Gattung",
            value: "Vergleichbare Kultur",
            shrubsTrees: "Euphorbia",
          },
          familyName: { text: "Art", placeholder: "z.B. Ficus benjamina" },
          name: { text: "Sorte", placeholder: "z.B. Rubella" },
          pottingDate: "Topftermin",
          spacingDate: "Rücktermin",
          deliveryDate: "Kulturende (geplant)",
          potSize: "Topfgröße",
          location: {
            text: "Position im Betrieb",
            placeholder: "z.B. 'Gewächshaus 2' oder 'Tunnel 1'",
          },
          rideNumber: "Auftragsnummer der Substratlieferung",
          soilAdditive: "Weitere Zusätze im Substrat",
          certification: {
            text: "Zertifizierungen",
            options: {
              mps: { text: "MPS" },
              globalGap: { text: "Global GAP" },
              skal: { text: "SKAL" },
            },
          },
          other: "Weitere Anmerkungen",
          button: "Speichern",
        },
        secondCategory: {
          title: "Anbaubedingungen",
          fertilization: {
            text: "Düngestrategie",
            placeholder: "z.B. Langzeitdünger (Osmocote)",
          },
          waterSource: {
            text: "Wasserherkunft",
            placeholder: "z.B. Brunnenwasser",
          },
          irrigation: {
            text: "Bewässerungssystem",
            placeholder: "z.B. Ebbe / Flutsystem, (Tröpfchenbewässerung)",
          },
          resource: "Pflanzenschutz",
          construction: {
            text: "Art des Anbaus",
            options: {
              containerField: { text: "Containerstellfläche (außen)" },
              greenhouse: { text: "Gewächshaus" },
              polytunnel: { text: "Folientunnel" },
              outdoorShadowNet: {
                text: "Freilandanbau unter einer Schattierung",
              },
              other: { text: "Andere" },
            },
          },
          extraRemarks: {
            text: "Zusätzliche Anmerkungen zu den Anbaubedingungen",
            placeholder: "z.B. biologischer Anbau",
          },
          button: "Speichern",
        },
        thirdCategory: {
          title: "Ziele und Herausforderungen der Kultur",
          comment: "",
          button: "Speichern",
        },
      },
      notes: {
        bigTitle: "Kultivierungshinweise",
        new: {
          title: "Neue Notizen",
          hiddenTitle: "Kategorie notieren",
          classButton: "+ Hinzufügen",
          saveButton: "Speichern",
        },
        saved: {
          title: "Gespeicherte Notizen",
          firstAttributes: "Datum:",
          secondAttributes: "Kategorie:",
          buttonAttributes: "Löschen",
          hiddenAttributes: "Hinweis",
        },
        categories: {
          waterAndFertilization: { label: "Bewässerung und Düngung" },
          cropControl: { label: "Pflanzenschutz" },
          cultivationActivity: { label: "Kulturmaßnahmen" },
          observation: { label: "Beobachtung" },
          misc: { label: "Sonstiges" },
        },
      },
      selectLanguage: { firstSelect: "Sprache auswählen" },
    },
  },
  userManagement: {
    title: "User management",
    user: {
      title: "User",
      name: "Name",
      permissions: "Permissions",
      role: "Role",
      active: "Active",
      actions: "Actions",
    },
    thirdParty: {
      title: "Third party users and requests",
      accept: "Accept",
      reject: "Reject",
      awaitingAction: "Awaiting action",
      revokeAccess: "Revoke access",
      requestAccess: "Request third party access",
      revoked: "Revoked access for third party user",
      submit: "Submit",
      requestSent: "Sent third-party invitation",
      alreadyInvited:
        "An invitation has been already sent to this email address",
      backToOverview: "Go back to overview",
    },
    save: "Save",
    userUpdated: "Updated user successfully!",
    userTypes: [
      { id: "user", name: "User" },
      { id: "admin", name: "Admin" },
    ],
    advisors: "Advisor users",
    emails: {
      accepted: {
        subject: "Ihre Drittanbieteranfrage wurde angenommen!",
        message: "Ihre Drittanbieteranfrage wurde angenommen!",
      },
      rejected: {
        subject: "Ihre Drittanbieteranfrage wurde abgelehnt!",
        message: "Ihre Drittanbieteranfrage wurde abgelehnt!",
      },
    },
  },
  admin: {
    title: "Administrator",
    companyName: "Name",
    contactPerson: "Contact person",
    addCompany: "Firma hinzufügen",
    addHelpText: "Der vollständige Name des neuen Unternehmens",
    defaultLanguage: "Default language",
    addLanguageHelpText: "Standardsprache",
    companyLabels: "Company properties",
    companyLabelsHelpText: "Set properties",
    cta: "Firma hinzufügen",
    advisorConfig: {
      title: "Berater hinzufügen",
      addAdvisor: "Berater hinzufügen",
      updateAdvisor: "Berater hinzufügen",
      deleteAdvisor: "Berater löschen",
      cancel: "Zurück",
      editAction: "Bearbeiten",
      thSlug: "Name",
      thCompanies: "Betriebe",
      thAction: "Betriebe",
      fieldSlug: "Slug",
      fieldCompanyIDs: "Betrieb",
      thirdPartyConfig: "Third party config",
    },
    roleConfig: {
      title: "Role Config",
      addRole: "Add role",
      editRole: "Edit role",
      rolesTable: {
        title: "Roles",
        columns: ["Role", "Actions"],
      },
      roleName: "Role name",
      permissionsTable: {
        columns: ["Action", "View", "Create", "Edit", "Delete"],
      },
      allowedLocations: {
        title: "Allowed locations",
        label: "Select allowed locations",
      },
      forAllCompanies: "Display for all companies",
      forThirdParty: "Intended for third parties",
      forThirdPartyDefault: "Assign by default to third parties",
      save: "Save",
      cancel: "Cancel",
    },
    userRoleConfig: {
      title: "User Role Config",
      usersTable: {
        title: "Users",
        columns: ["Name", "Role", "Actions"],
      },
      save: "Save",
    },
    sensorConfig: {
      title: "Sensor Config",
      selectCompany: "Betrieb wählen",
      save: "Speichern",
    },
    weatherDataConfig: {
      title: "Wetterdaten",
      addWeather: "Wetterdaten hinzufügen",
      selectCompany: "Betrieb wählen",
      selectLocation: "Standort wählen",
      uploadWeatherData: "Wetterdaten hochladen",
      save: "Speichern",
    },
    locationsConfig: {
      title: "Standorte",
      updateLocations: "Standorte aktualisieren",
      selectLocation: "Standort wählen",
      addLocation: "Standort hinzufügen",
      noLocations: "No locations",
      cancel: "Zurück",
      editAction: "Zurück",
      fieldSlug: "Slug",
      thSlug: "Slug",
      thLocations: "Stadnorte",
      thAction: "Action",
      thName: "Name",
      thLatitude: "Breitengrad",
      thLongitude: "Längengrad",
    },
    qrCodeSubstrate: {
      title: "QR Code Substrate",
      uploadFormTitle: "Upload substrate file",
      upload: "Upload",
      success: "{nr} substrate entries created",
      failure: "Something went wrong while creating substrate entries",
    },
    analyticsConfig: {
      title: "Analytics",
      btnCompanyAnalytics: "Download company analytics",
      btnTrackingAnalytics: "Download tracking analytics",
    },
  },
  companyProfile: {
    title: "Firmenprofil",
    companyId: "Company ID",
    labCustNumber: "Lab customer number",
    invite: "Benutzer einladen",
    inviteType: "Invitation type",
    inviteTypes: [
      { id: "user", name: "User" },
      { id: "admin", name: "Admin" },
    ],
    inviteEmailType: "Invitation email type",
    inviteEmailTypes: [
      { id: "newCustomer", name: "New customer" },
      { id: "substrateSampleService", name: "Substrate sample service" },
      { id: "cropServiceLabAnalyses", name: "Crop service, lab analyses" },
    ],
    addThirdPartyUsers: "Add third party users",
    inviteCta: "Sende Einladung",
    inviteSuccess: "Einladung versendet!",
    userManagement: "Benutzerverwaltung",
    inviteHelp:
      "Gewähren Sie Zugriff auf Ihr Dashboard, indem Sie hier Mitglieder hinzufügen. Geben Sie eine E-Mail-Adresse ein, um einen neuen Benutzer einzuladen.",
    InvitationLink: "Loginlink",
    noPreferredLanguage: "Keine bevorzugte Sprache",
    sidebar: {
      profile: "Profile",
      sensorConfig: "Sensorkonfiguration",
      locationConfig: "Standortkonfiguration",
      exports: "Exporte",
      tasks: "Aufgaben",
      noteChecklists: "Note checklists",
      tankMixes: "Crop protection tank mixes",
      cropsImport: "Ernten importieren",
      locationsQr: "Standort-QR-Code",
      userManagement: "Benutzerverwaltung",
      protocols: "Protocols",
    },
  },
  sensorConfig: { providers: { blockbax: "Blockbax", tentacles: "Tentacles" } },
  general: {
    sidebar: {
      documents: "Unterlagen",
      climate: "Klima",
      fertilization: "Düngung",
      notes: "Anmerkungen",
      labAnalyses: "Laboranalysen",
      advices: "Ratschläge",
    },
  },
  languages: { nl: "Dutch", en: "English", de: "German", fr: "French" },
  profile: {
    title: "Betriebsprofil",
    name: "Name",
    phone: "Phone",
    password: "Passwort",
    confirmPassword: "Bestätige das Passwort",
    passwordsDontMatch: "Passwörter stimmen nicht überein!",
    changePassword: "Ändere das Passwort",
    preferredLanguage: "Preferred language",
    youngPlantSuppliers: "Young plant suppliers",
    sensorProvider: "Sensor provider",
    bulbGrower: "Bulb grower",
    taskSensorNotificationPreference: "Task / sensor notification preference",
    contact: {
      title: "Kontakt",
      companyName: "Firmenname",
      clientNumber: "Kundennummer",
      adress: "Adresse",
      contactPerson: "Kontaktperson",
      phoneNumber: "Telefonnummer",
      email: "E-Mail-Adresse",
    },
    location: {
      title: "Betriebsstandorte",
      locationAmount: {
        title: "Anzahl der Standorte",
        placeholder: "z.B. 3 Topfpflanzengärtnereien",
      },
      surface: { title: "Nettokulturfläche", placeholder: "z.B. 5 Hektar" },
      cropAmount: {
        title: "Anzahl der angebauten Kulturen",
        placeholder: "Gesamtzahl der Pflanzenarten in Ihrem Betrieb",
      },
      notes: {
        title: "Weitere Anmerkungen",
        placeholder: "Wichtige Hinweise für Ihren Berater",
      },
      addLocation: "Standort z.B. Berlin",
      locationsList: "Standortliste",
      addLocationLevel1: "Abteilung z.B. Haus 1, 2..",
      editLocationLevel1: "Abteilung z.B. Haus/Feld 1, 2..",
      addLocationLevel2: "Untereinheit z.B. Schiff/Schlag 1, 2..",
      editLocationLevel2: "Untereinheit z.B. Schiff/Schlag 1, 2..",
      addSurfaceArea: "Fläche m²",
      editSurfaceArea: "Fläche bearbeiten",
    },
    growingConditions: {
      title: "Anbaubedingungen",
      fertilizationMethod: {
        title: "Düngungsstrategie",
        placeholder: "z.B. Langzeitdünger",
      },
      waterSource: {
        title: "Wasserherkunft",
        placeholder: "z.B. Brunnenwasser, Regenwasser",
      },
      waterSample: {
        title: "Bewässerungssystem",
        placeholder: "z.B. Ebbe / Flut, Tröpfchenbewässerung",
      },
      environment: {
        title: "Anbausystem",
        setupMethods: {
          containerField: { text: "Containerstellfläche (außen)" },
          greenhouse: { text: "Gewächshaus" },
          openSoil: { text: "Freiland" },
          polytunnel: { text: "Folientunnel" },
          other: { text: "Andere" },
        },
        type: { title: "Anbau (geschützt/Freiland)" },
        notes: {
          title: "Zusätzliche Informationen",
          placeholder: "Wichtige Hinweise für Ihren Berater",
        },
        challenges: {
          title: "Herausforderungen/Schwerpunkte",
          placeholder:
            "z.B. biologischer Pflanzenschutz, Wasserverbrauch minimieren",
        },
        certification: {
          title: "Zertifizierungen",
          placeholder: "z.B. MPS A+, Global G.A.P.",
        },
        buttonText: "Speichern",
      },
    },
    cropFields: {
      title: "Optional crop fields",
    },
    pdfFields: {
      title: "Optional pdf fields",
    },
    cultivationActivities: { title: "Select your activities" },
    measurementTypes: { title: "Select measurement types in note form" },
    rating: { title: "Select rating items in note form" },
    noteLabels: { title: "Select custom note labels in note form" },
    photoLabels: { title: "Select custom photo labels in note form" },
    wateringUnits: { title: "Select default watering unit in note form" },
    cropLabels: {
      fields: "Select crop labels fields",
      sizes: {
        select: "Select crop labels size",
        large: "Large",
        small: "Small",
      },
    },
    settings: {
      title: "Wetter Einstellungen",
      weatherStats: {
        title: "Wetterdaten für Wochendurchschnitt",
        placeholder: "",
        options: {
          tavgAvg: "tavgAvg",
          tminAvg: "tminAvg",
          tmaxAvg: "tmaxAvg",
          humAvg: "humAvg",
          irrSum: "irrSum",
          leafSum: "leafSum",
          rainSum: "rainSum",
        },
      },
      notificationSettings: {
        title: "Benachrichtigungseinstellungen",
        sensorAlerts: "Sensorwarnungen",
        taskAlerts: "Aufgabenbenachrichtigungen",
        dailySummaryEmails: "Tägliche zusammenfassende E-Mails",
        endOfCultivationAlertsEmails:
          "E-Mail-Benachrichtigungen zum Ende der Kultivierung",
      },
    },
    savedSuccessfully: "Profil aktualisiert!",
    unsubscribedSuccessfully: "Unsubscribed from daily alerts.",
    wateringItems: {
      title: "Felder enhalten in Notiztyp Bewässerung + EC + Entwässerung",
    },
  },
  sensors: {
    title: "Sensoren",
    config: "Sensorkonfiguration",
    loading: "Sensoren laden",
    selectSensors: "Sensoren auswählen",
    selectAll: "Alles auswählen",
    backToAll: "zurück zu allen",
    pickYourSensors: "Wählen Sie Ihre Sensoren aus",
    sensorId: "id",
    externalId: "externe ID",
    allSensors: "Alle Sensoren",
    noSensors: "Keine registrierten Sensoren",
    noConnectedCrops: "Keine verbundenen Kulturen",
    loadingMeasurements: "Loading measurements",
    noMeasurements: "Keine Messungen",
    editSensor: {
      title: "Sensor bearbeiten",
      connectedCrops: {
        title: "Kulturen",
        add: "hinzufügen",
        edit: "ändern",
        remove: "Löschen",
        addEdit: "Hinzufügen/ändern sensor Kulture",
        crop: "Kulture",
        startEndDate: "Select start / end date",
        noStartDate: "Kein Startdatum",
        noEndDate: "Kein Enddatum",
      },
      dataYAxisBounds: {
        label: "Grenzen der Daten-Y-Achse",
        options: {
          default: "Standardgrenzen",
          boundByValues: "Gebunden durch Messungen",
        },
      },
      highSoilMoisture: "Hohe Bodenfeuchte",
      highTemperature: "Hohe Temperatur",
      lowSoilMoisture: "Niedrige Bodenfeuchte",
      lowTemperature: "Niedrige Temperatur",
      receiveAlerts: "Meldungen erhalten",
      button: "Speichern",
    },
    timeRange: {
      title: "Zeitspanne",
      placeholder: "Bereich auswählen",
      options: {
        lastDay: "Letzter Tag",
        lastWeek: "Letzte Woche",
        lastMonth: "Letzten Monat",
        lastYear: "Last year",
      },
    },
    settings: "Settings",
    noSensorMessage:
      "Es ist möglich, Sensoren mit Ihrem persönlichen Log & Solve-Dashboard zu verbinden. Es stehen zwei Arten von Sensoren zur Verfügung, die derzeit angeschlossen werden können. Beide werden direkt in den Topf gegeben, um verschiedene Parameter zu messen. Die beiden Sensortypen werden ungefähr wie folgt beschrieben:",
    sensorOffer1:
      "Messen Sie den Feuchtigkeitsgehalt und die Temperatur in Ihrem Topf 4 Mal pro Stunde.",
    sensorOffer2:
      "Messen Sie den Bodenfeuchtigkeitsgehalt und die Temperatur in Ihrem Topf - und die Luftfeuchtigkeit, Lichtintensität und Lufttemperatur knapp über Ihrem Topf 12 Mal pro Stunde.",
    sensorOfferContact:
      "Die Wahl des Sensortyps hängt von Ihren persönlichen Wünschen, der Art der Ernte und dem Anbausystem ab. Für weitere Informationen können Sie Co-Anne van Waaij über co-anne.vanwaaij@klasmann-deilmann.com oder +31 6 13 53 50 54 kontaktieren.",
    sensorOfferDisclaimer:
      "Hinweis: Zu diesem Zeitpunkt können Daten von Ihrem Klimacomputer noch nicht mit Log & Solve verbunden werden.",
  },
  locations: {
    title: "Standorte",
    config: "Standorte konfiguration",
    businessLocations: "Geschäftsstandorte",
    locations: "Standorte",
    location: "Standorte",
    slevels: ["Geschäftsstandorte", "Standorte Level 1", "Standorte Level 2"],
    mlevels: ["Geschäftsstandorte", "Level 1 Standorte", "Level 2 Standorte"],
    divideSurface: "Teilen Sie die Fläche gleichmäßig auf",
    surfaceArea: "Oberfläche",
    addNew: "Füge einen neuen Level {lvl} Standort hinzu",
    edit: "Bearbeiten",
    remove: "Entfernen",
    saveAdd: "Speichern + neu",
    save: "Speichern",
    cancel: "Absagen",
    onlySameBusinessLocs:
      "Sie können den Standort für Kulturen mit unterschiedlichen Unternehmensstandorten nicht ändern.",
    errorSavingLocations:
      "Fehler beim Aktualisieren der Standortkonfiguration des Unternehmens!",
  },
  cropsQr: {
    title: "QR-Code des Ernteetiketts",
    urlType: {
      label: "URL type",
      options: [
        {
          id: "NotizFormular",
          name: "Notiz formular",
          docLabel: "Hinweis - Foto",
        },
        { id: "cropLog", name: "Crop log", docLabel: "logboek" },
        { id: "cropProfile", name: "Crop profile", docLabel: "Anbauprofil" },
      ],
    },
    week: "Woche",
    objectcode: "Objektcode",
    ends: "Endet",
    start: "Start",
    end: "Ende",
    wk: "wk",
    cropLabel: "Anbauetikett",
    openField: "Open field",
  },
  locationsQr: {
    title: "QR-Code für standorte",
    cropsAtLocations: "Note by crops at location",
    notitie: "Notitie",
    foto: "foto",
  },
  exports: {
    title: "Exporte",
    cropControl: {
      fileType: "Dateityp",
      export: "Exporte",
      typeOptions: {
        cropProtection: "Ernteschutz",
        fertilization: "Düngung",
        cultivation: "Anbau",
      },
      button: "Erntekontrolle exportieren",
    },
  },
  exporters: {
    exportPdf: "To PDF",
    exportXlsx: "To Excel",
    exportCsv: "To CSV",
  },
  assesment: { title: "Dokument {name}" },
  weather: {
    title: "Wetterhistorie",
    tavgAvg: "Temperatur",
    tminAvg: "Temperatur min",
    tmaxAvg: "Temperatur max",
    humAvg: "Feuchtigkeit",
    irrSum: "Bestrahlung",
    rainSum: "Regen",
    leafSum: "Blatt",
  },
  signUp: {
    title: "Anmelden",
    errors: {
      emailInUse: "E-Mail-Adresse wird schon verwendet.",
      passwordWeak: "Das Passwort sollte mindestens 6 Zeichen lang sein",
    },
  },
  signIn: {
    title: "Einloggen",
    generalErrors: {
      tooManyRequests: "Zu viele Anmeldeversuche in kurzer Zeit.",
      networkRequestFailed: "Keine Internetverbindung.",
      default: "Ein unbekannter Fehler ist aufgetreten: {0}",
    },
    form: {
      emailAddress: {
        text: "E-Mail-Addresse",
        errors: {
          invalidEmailAddress: "Ungültige E-Mail Adresse",
          userEmailNotFound: "Kein Account mit dieser E-Mail Adresse gefunden.",
          userDisabled: "User currently disabled.",
        },
      },
      password: { text: "Passwort", error: "Falsches Passwort." },
      button: "Sign In",
      linkText: "Passwort vergessen?",
      noAccountText:
        "If you do not have an account yet, request an account here",
    },
  },
  signedInRedirect: { text: "Einloggen ..." },
  signOut: { title: "Erfolgreich abgemeldet", linkText: "Wieder anmelden?" },
  disclaimer: {
    titleOne: "Haftungsausschluss",
    textOne:
      "Wir übernehmen keinerlei Gewähr für die Aktualität, Korrektheit und Vollständigkeit der bereitgestellten Düngeempfehlungen. Haftungsansprüche gegen uns, die sich auf Schäden materieller Art oder auf Folgeschäden beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Düngeempfehlungen bzw. durch die Nutzung fehlerhafter und unvollständiger Empfehlungen verursacht wurden, sind grundsätzlich ausgeschlossen, [sofern uns kein nachweislich vorsätzliches oder grob fahrlässiges Handeln oder Unterlassen  vorgeworfen werden kann]. Dieser Haftungsausschluss greift nicht bei Personenschäden oder einer Haftung aus Delikt. Wir behalten uns ausdrücklich vor, Log & Solve oder Teile davon ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Nutzung zeitweise oder endgültig einzustellen.",
    titleTwo: "Interpretation von Kulturdaten",
    textTwo:
      "Die automatische Beurteilung der Analyseergebnisse in Log & Solve erfolgt kulturspezifisch. Die Bewertung erfolgt auf der Basis des niederländischen Düngeempfehlungssystems ‚Bemesting Advies Basis‘, ergänzt durch Erfahrungswerte und Forschungsergebnisse der Universität Wageningen*.  Für die Beratung werden die Kulturen entsprechend Ihres Nährstoffbedarfes, der Salzempfindlichkeit und der pH-Toleranz  kategorisiert. Dieses Düngeempfehlungssystem ist ein detaillierter Leitfaden, um Analyseergebnisse kulturspezifisch bewerten und eine möglichst exakte Düngeberatung erstellen zu können. Sowohl der Kulturfortschritt als auch die durchgeführte Düngung vor Probennahme sind für die Interpretation und die Erstellung einer Düngeberatung zu berücksichtigen. Es ist auch möglich, dass Sie im Laufe der Jahre innerhalb Log & Solve eigene betriebsspezifische Referenzwerte entwickeln.  Die Ziel- und Grenzwerte in Log & Solve basierend  auf der  ‚Bemesting Advies Basis‘ sind daher grundsätzlich als allgemeine Richtwerte zu interpretieren. \n \n * Bei der automatischen Auswertung der Analyseergebnisse werden die Analysedaten kulturspezifisch zunächst in einen EC-Standardwert umgerechnet. Dadurch können die Analyseergebnisse proportional zum EC-Wert umgerechnet und im Zeitverlauf miteinander verglichen werden.",
  },
  overview: { title: "Liste der Produzenten", searchGrower: "Search grower" },
  team: {
    intro:
      "Log & Solve ist ein digitales Tool, das vom Inkubator von Klasmann-Deilmann entwickelt und betrieben wird. Lernen Sie unser Team kennen",
    members: [
      {
        name: "Sjors Beijer",
        portrait: "sjors.jpg",
        position: "Innovation Manager Klasmann-Deilmann",
        description:
          'Sjors kam im Juni 2016 als Innovationsmanager zu Klasmann-Deilmann, nachdem er sein Studium an der Universität Wageningen abgeschlossen hatte. Sjors war einer der Initiatoren von Log & Solve. " Der Trend zu einem schonenderen Umgang mit natürlichen Ressourcen und zu einem nachhaltigeren Anbau mit weniger Chemikalieneinsatz erfordert eine Änderung der Anbaustrategie. Das Überwachen der Kulturführung ist ein wesentlicher Bestandteil dieser Anpassung. Ein gutes Monitoring hilft den Gärtnern spezifisches Anbauwissen besser zu erfassen, zu optimieren und im Unternehmen zu speichern. Meiner Meinung nach können digitale Dienste für Topfpflanzenproduzenten einen großen Mehrwert darstellen, da sie eine proaktive  Problemlösung ermöglichen und Kulturausfälle auf benutzerfreundliche Weise reduzieren. Log & Solve wurde für - aber vor allem zusammen mit den Gärtnern - entwickelt."',
      },
      {
        name: "Co-Anne van Waaij",
        portrait: "co-anne.jpg",
        position: "Business Developer",
        description:
          "Co-Anne ist eines der enthusiastischen und innovativen Talente bei Klasmann-Deilmann. Sie trat 2018 in das Unternehmen ein und ist Ihre Ansprechpartnerin für Log & Solve. Co-Anne arbeitet eng mit Gärtnern zusammen, um für Log & Solve die besten Einsatzmöglichkeiten für die täglichen Anforderungen und Arbeitsprozesse in den Gartenbaubetrieben auszuloten. „Ich arbeite gerne mit den Gärtnern zusammen, um die Praxis mit Log & Solve abzugleichen, damit dieses neue digitale Tool einen Mehrwert für sie darstellt. Ich bin überzeugt, dass diese Arbeitsweise, die man Co-Creation nennt, zum Erfolg für alle führt.“",
      },
      {
        name: "Gerard Wapenaar",
        portrait: "gerard.jpg",
        position: "Technischer Berater",
        description:
          'Gerard arbeitet seit vielen Jahren in der Substrat- und Gartenbaubranche. Als Produktmanager bei Klasmann-Deilmann sieht Gerard Log & Solve als leistungsstarkes Werkzeug für Gärtner, mit dem das jeweilige Kulturverfahren schneller und effizienter gestaltet werden kann. „Rechtzeitiges Handeln - basierend auf gespeicherten Kulturdaten, z. B. aus Vorjahren kann den Kulturerfolg verbessern. Ich erstelle Beurteilungen von Substrat-, Nährstoff- und Wasseranalysen, die durch Log & Solve verarbeitet werden. Außerdem stehe ich als Sparringspartner für die Bewässerungsdüngung zur Verfügung".',
      },
      {
        name: "Hermann Konnemann",
        portrait: "hermann.jpg",
        position: "Technischer Berater",
        description:
          "Hermann arbeitet seit 2007 als Technischer Berater für Klasmann-Deilmann, nachdem er einige Jahre als Versuchsingenieur in der gartenbaulichen Forschung tätig war. Basierend auf seiner Beratungstätigkeit für Gärtner in Europa und Fernostasien, sieht er die Vorteile von Log & Solve darin, dass „die Kulturberatung damit schneller und verlässlicher erfolgen kann. Zu einem Analyseergebnis kann ich als Berater künftig via Log & Solve auch das Kulturstadium direkt mit gespeicherten Bildern abgleichen und zusätzliche Notizen sowie Sensordaten zur Kulturführung einsehen. Die Qualität der Beratung wird dadurch erhöht.“",
      },
      {
        name: "Balie Nasution",
        portrait: "balie.jpg",
        position: "Product Owner und Administrator",
        description:
          'Balie kam im Sommer 2019 als internationaler Trainee zu Klasmann-Deilmann. Mit seinem bodenkundlichen und pflanzenwissenschaftlichen Hintergrund sowie seinen ausgeprägten Interessen und Fähigkeiten in der Web-Entwicklung und im Datenmanagement passt er hervorragend in das Log & Solve Team. „In Zukunft wird die Arbeit stärker datenbasiert sein. Dieses wird dazu beitragen, die Transparenz in den Lieferketten zu erhöhen und Entscheidungen aufgrund von Kulturdaten statt über den "grünen Daumen" zu treffen. Digitale Tools wie Log & Solve sollen Kulturdaten aus Gegenwart und Vergangenheit bereitstellen, so dass Gärtner letztendlich in der Lage sind, ihre Produktionseffizienz zu verbessern“.',
      },
      {
        name: "Sören Houwing",
        portrait: "soren.jpg",
        position: "Coordinator International Markets",
        description:
          "Seit Herbst 2016 verstärkt Sören das Team Technical Sales Coordination bei Klasmann-Deilmann. Durch eine Ausbildung zum Gärtner und einem sich später anschließenden Gartenbaustudium an der Hochschule Osnabrück hat Sören selbst viele praktische Erfahrungen im Gartenbau machen können. Seine Affinität zu IT-gestützten Arbeitsprozessen begeistert ihn für den digitalen Service Log & Solve: „In der Ausbildung und als Gärtner musste ich viele Daten analog erfassen und hätte mir ein digitales Tool wie Log & Solve gewünscht. Deshalb freue ich mich sehr, die Entwicklung von Log & Solve unterstützen zu dürfen, um Arbeitsabläufe in Gartenbaubetrieben einfacher, schneller und effizienter zu gestalten.“",
      },
    ],
  },
  cropsList: [
    { text: "Benachrichtigung" },
    { text: "Hinzufügen" },
    { text: "Hinzugefügt zu" },
    { text: "Alle" },
  ],
  cropsListPage: {},
  tableComponent: {
    icon: "Icon",
    showColumns: "Spalten auswählen",
    noColumns: "Wählen Sie die anzuzeigenden Spalten aus",
    progress: "Kulturstatus",
    alerts: "Aktualisierungen",
    reset: "Zurücksetzen",
    label: "Etikette",
    date: "Topftermin",
    weekNr: "Kalenderwoche (Start)",
    potDate: "Topfwoche",
    potSize: "Topfgröße",
    tray: "Tray",
    trayCellsAmount: "Number of cells per tray",
    traysAmount: "Number of trays",
    plugType: "Plug type",
    growcoon: "Growcoon type",
    openField: "Open field",
    startingNumberOfPots: "Start number",
    currentNumberOfPots: "Current number",
    genus: "Gattung",
    genusName: "Genus",
    speciesName: "Species",
    cultivarName: "Cultivar",
    labDataId: "Labor-ID",
    barcode: "Barcode",
    batchNumber: "Chargennummer",
    youngPlantSuppliers: "Young plant suppliers",
    bulbSize: "Bulb size",
    personPlanted: "Person who planted",
    isTrialCrop: "Nur Versuche anzeigen",
    species: "Spezies",
    cultivar: "Sorte",
    location: "Standort",
    businessLocation: "Betriebsstandort",
    locationLevel1: "Abeilung",
    locationLevel2: "Location Level 2",
    variety: "Sorte",
    measure: "Topf",
    sort: "Sortieren",
    show_location: "Standort anzeigen",
    status: "Inaktive Kulturen",
    search_placeholder: "Suche ernte ...",
    select: { all: "Alle", month: "Month", week: "Week" },
    next: "Nächster",
    prev: "Vorherige",
    noData: "Keine Daten :(",
    locationToggle: "Standort anzeigen",
    labDataIdToggle: "Labor-ID anzeigen",
    alert: {
      "new-note": "NOTIZ",
      "new-photo": "FOTO",
      "lab-data": "LABORDATEN",
      "lab-data-comment": "LABORDATEN KOMMENTAR",
      "lab-data-pending": "AUSSTEHENDE LABORDATEN",
      "new-task-due": "AUFGABE FÄLLIG",
      protocol: "WATCH OUT FOR",
      "new-advice": "RAT",
      "new-sensor-trigger": "SENSOR TRIGGER",
      "new-note-mention": "NOTE MENTION",
    },
    actions: {
      generateQrCodes: "QR-Codes generieren",
      duplicateCrops: "Duplikat",
    },
    advanced: {
      label: "fortschrittlich",
      title: "Erweiterte Crop-Filter",
      add: "Hinzufügen",
      update: "Aktualisieren",
      execute: "ausführen",
      clear: "Klar",
    },
    view: "View",
    cropLabel: "Crop label",
  },
  notificationLis: { new: "Neu" },
  addCrop: {
    duplicateCrop: "Kulturprofil duplizieren",
    selectCrop: "Kulturprofil auswählen",
    phase: "Erntephase",
    classification: "Classification",
    referenceGenus: "Referenzgattung",
    noteDate: "Date",
    duplicateForm: "Duplikat von",
    cropType: "Kulturbezeichnung",
    genericName: "Gattung",
    choose: "Wählen",
    specificEpithet: "Spezies",
    cultivar: "Sorte",
    chooseYourName: "Wähle deinen Namen...",
    or: "Oder",
    details: "Kulturinformationen",
    potSize: "Topfgröße",
    startingNumberOfPots: "Start number",
    currentNumberOfPots: "Current number",
    numberOfCellsPerTray: "Number of cells per tray",
    numberOfTrays: "Number of trays",
    plugType: "Plug type",
    growcoonType: "Growcoon type",
    drippers: "Drippers",
    startingAmount: "Starting amount",
    currentAmount: "Current amount",
    batchNumber: "Chargennummer (Substrat)",
    bulbSize: { title: "Bulb size", placeholder: "Bulb size" },
    personPlanted: {
      title: "Person, die gepflanzt hat",
      placeholder: "Person, die gepflanzt hat",
    },
    number: "Nummer",
    cultivationTime: "Kulturzeit",
    dateOfPotting: "Topftermin",
    endDate: "End date",
    expectedCultivationTime: "Erwartete Kulturzeit (in Wochen)",
    numberOfWeeks: "Anzahl der Wochen",
    trialCrop: "Versuch",
    submit: "Senden",
    delete: "Delete",
    confirmDelete: "Are you sure you want to delete this crop?",
    search: "Suche",
    next: "Nächster",
    back: "Zurück",
    cropAddedSuccessfully: "Neue Kultur hinzugefügt!",
    cropEditedSuccessfully: "Kultur erfolgreich bearbeitet!",
    cropDeletedSuccessfully: "Crop deleted successfully!",
    edit: "Bearbeiten Kultur",
    weekInYear: "Kalenderwoche",
    description: "Beschreibung",
    descPlaceholder: "Pflanzenbeschreibung..",
    labDataId: "Lab ID",
    barcode: "Barcode",
    labIdNotUnique: "Diese Lab-ID ist bereits vorhanden",
    statusActive: "Aktiv",
    statusInactive: "Inaktiv",
    recipe: "Substratrezeptur",
    productionDate: "Liefertermin",
    orderNo: "Bestellnummer",
    targetPh: "Ziel pH",
    targetEc: "Ziel EC",
    substrateMixture: "Substratmischung",
    recipeDescription: "Substratmischung",
    addSubstrateInfo: "Substratinformationen hinzufügen",
    loadingSubstrateOrder: "Substratbestellinformationen werden geladen...",
    noSubstrateOrder:
      "Es gibt keine Bestellinformationen für die aktuelle ID, Kontakt",
    protocol: "Protocol",
    fertilization: "Fertilization",
    containerOptions: [
      { id: "pot", label: "Pot" },
      { id: "tray", label: "Tray" },
      { id: "open", label: "Open field" },
    ],
    plugTypeOptions: [
      { id: "loose", name: "Loose filled trays" },
      { id: "growcoon", name: "Growcoon" },
      { id: "paper", name: "Paper Pot" },
      { id: "glued", name: "Glued plugs" },
      { id: "rockwool", name: "Rockwool" },
      { id: "oasis", name: "Oasis" },
    ],
    trayName: "Tray name",
    cropLabelName: "Label name",
    cropLabel: "Crop label",
    cropLabels: ["Cultivation trial", "Main crop"],
    objectives: "Objectives and challenges for this crop",
    spacingDate: "Arrival date of young plant material",
    container: "Container",
    businessLocation: "Business location",
    labId: "Lab ID",
    openField: "Open field",
    tray: "Tray",
  },
  inputComponent: {
    loading: "Lade Daten..",
    search_placeholder: "Bitte tippe etwas...",
  },
  noteChecklists: {
    title: "Note checklists",
    createNew: "Create new",
    createEdit: "Create or modify checklist",
    name: "Checklist name",
    selectBranch: "Select a branch",
    displayIn: "Display in",
    customLabel: "Custom label",
    save: "Save checklist",
    noChecklists: "Nothing to see here",
    selectChecklist: "Select a checklist",
  },
  tankMixes: {
    title: "Crop protection tank mixes",
    createNew: "Create new",
    createEdit: "Create or modify tank mix",
    name: "Tank mix name",
    type: "Tank mix type",
    save: "Save tank mix",
    newMix: "New mix",
    predefinedMix: "Predefined mix",
    emptyProduct: "Empty product",
    noTankMixes: "Nothing to see here",
    selectTankMix: "Select a tank mix",
    selectPredefinedMix: "Choose pre-defined mix",
  },
  documents: {
    platform: "Platform documents",
    company: "Company documents",
    crop: "Crop documents",
    categories: [
      { id: "third-party", label: "Third party" },
      { id: "log-and-solve", label: "Log & Solve" },
      { id: "documentation", label: "Documentation" },
    ],
    showAll: "Show all",
    add: "Add",
    edit: "Edit",
    document: "document",
    title: "Title",
    category: "Category",
    type: "Type",
    shareWith: "Share with",
    allCompanies: "All companies",
    allIn: "All in",
    file: "File",
    link: "Link",
    submit: "Submit",
    noEntries: "Nothing to see here",
    crops: "crops",
    description: "Description",
  },
  advices: {
    title: "Advices",
    advice: "advice",
    description: "Description",
    viewAttachment: "View attachment",
  },
  generalNotes: {
    title: "Notes",
    note: "note",
    selectLocation: { label: "Standort wählen", placeholder: "Pick locations" },
    description: "Description",
    viewAttachment: "View attachment",
    includeInCrops: "Include in crops",
  },
  climateData: {
    title: "Climate data",
    paramTypes: [
      { id: "set", name: "Set" },
      { id: "achieved", name: "Achieved" },
    ],
    parameters: {
      temperature: "Temperature",
      light: "Light",
      airh: "Air humidity",
      co2: "CO2",
      radiation: "Radiation",
      "ventilation-a": "Ventilation A",
      "ventilation-b": "Ventilation B",
      shading: "Shading",
      substrate_temperature: "Substrate temperature",
    },
    timeframes: {
      "24h": "24h",
      day: "day",
      night: "night",
      agendaWeekNr: "agendaWeekNr",
      "24h-avg": "24h-avg",
      week: "week",
    },
    units: {
      c: "℃",
      f: "f",
      "j/cm2-som": "j/cm2-som",
      "mol/m2": "mol/m2",
      "%": "%",
      av: "av",
      vd: "vd",
      ppm: "ppm",
      "wind m/s": "wind m/s",
      radiation: "radiation",
      airh: "airh",
      "w/m2": "w/m2",
      "umol/m2/s": "umol/m2/s",
      daylength: "daylength",
      h: "h",
      "kg/ha": "kg/ha",
    },
    wizard: {
      title: "Register new",
      manage: "Manage entries",
      selectLocations: "Select locations",
    },
    settings: {
      title: "Settings",
      manage: "Manage settings",
      settingsUpdated: "Settings have been updated",
    },
    week: "Week",
    save: "Save",
    getStarted: "Create a new climate data settings object to get started",
    createSettings: "Create",
  },
  fertilizationBins: {
    title: "Fertilization",
    wizard: {
      title: "Register new",
      manage: "Manage entries",
      pickBin: "Pick a bin",
    },
    settings: {
      title: "Settings",
      manage: "Manage settings",
      createNew: "Create new",
      createEdit: "Create or modify fertilization bin",
      selectLocations: "Select locations",
      noBins: "No fertilization bins",
      settingsUpdated: "Settings have been updated",
    },
    name: "Bin name",
    type: "Bin type",
    concentration: "Concentration",
    bin: "Bin",
  },
  pendingLabAnalysis: {
    title: "Pending lab analyses",
    submit: "Submit",
    noEntries: "Nothing to see here",
    completed: "{nr} lab analysis entries have been applied",
  },
  cropsImport: {
    title: "Import crops",
    menu: { wizard: "Wizard", settings: "Settings" },
    inputFormats: {
      voorbeeld: "Wintree TSD",
      wouters: "Mprise agriware",
      voorraadpaartij: "GroenVision",
    },
    getStarted: "Create a new crop imports settings object to get started",
    createSettings: "Create",
    selectFile: "Select Excel import file",
    importFile: "Import file",
    reset: "Reset",
    progress: {
      created: "Created",
      updated: "Updated",
      skipped: "Skipped",
      failed: "Failed",
    },
    execute: "Execute",
    completed: "Crops import has been completed",
    validationError: "Please verify your input and try again",
    settings: {
      familyCodes: "Family codes",
      familyCode: "Family code",
      locationCodes: "Location codes",
      locationCode: "Location code",
      potsizeCodes: "Potsize codes",
      fieldsMaps: "Fields maps",
      dedicatedLocCols: "Dedicated location columns",
      save: "Save",
      settingsUpdated: "Crops import settings updated",
    },
  },
  tableHeaders: {
    barcode: "Barcode",
    genus: "Genus",
    species: "Species",
    cultivar: "Cultivar",
    ownName: "Own name",
    container: "Container",
    potSize: "Pot size",
    tray: "Tray",
    plugType: "Plug type",
    growcoon: "Growcoon",
    cellsPerTray: "Number of cells per tray",
    trays: "Number of trays",
    startingAmount: "Starting amount",
    currentAmount: "Current amount",
    batchNo: "Batch no.",
    youngPlantSupplier: "Young plant supplier",
    locationCode: "Location code",
    businessLocation: "Business location",
    level1Location: "Level 1 location",
    level2Location: "Level 2 location",
    weekNumber: "Week number",
    pottingStart: "Potting start",
    pottingEnd: "Potting end",
    expectedCultivationTime: "Expected cultivation time (in weeks)",
    fertilization: "Fertilization",
    substrateMixture: "Substrate mixture",
    description: "Description",
    sizeDescription: "Size description",
    supplierDocumentNr: "Supplier document nr.",
    containerNr: "Container number",
    protocol: "Protocol",
    label: "Label",
    labelName: "Label name",
    phase: "Phase",
    objectives: "Objectives",
    spacingDate: "Arrival date of young plant material",
    bulbSize: "Bulb size",
    person: "Person",
    drippers: "Drippers",
  },
  cropActions: {
    duplicateCrops: {
      title: "Duplicate crops",
      progress: {
        created: "Created",
        updated: "Updated",
        skipped: "Skipped",
        failed: "Failed",
      },
      execute: "Execute",
      proceed: "Proceed",
      completed: "{count} of {total} crops have been duplicated successfully",
      validationError: "Please verify your input and try again",
    },
  },
  cropForm: {
    selected: "Selected {nr} crops",
    selectLocation: "Standort wählen",
    selectYourLocation: "Select your location",
    cropsSelected: "Total of {nr} crops selected",
    noActiveCrops: "Currently no crops are active in this location",
    selectCrops: "Kultur wählen",
    crops: "crops",
    locations: "Locations",
    selectedLocations: "Selected locations",
    search: "Suche",
    selectAll: "Select all",
    showMore: "Show more",
    stepNo: "Step {cs} of {ms}",
    labData: "Laborwerte",
    climateData: "Climate data",
    fertilizationBins: "Fertilization bins",
    advice: "Advice",
    "general-note": "General note",
    checklist: "Checklist",
    fertilizationEc: "EC during watering",
    watering: "Bewässerung",
    ecWateringDrainage: "Bewässerung + EC + Entwässerung",
    drainage: "Entwässerung",
    measurementEC: "EC-measurement",
    measurementpH: "pH measurement",
    EC: "EC",
    "fertilization-Water": "Bewässerung",
    amountSpreadable: "Düngermenge",
    concentrationSpreadable: "Konzentration",
    "fertilization-fertilization": "Blattdüngung",
    amountAgent: "Mittelmenge",
    choose: "Wählen",
    cause: "Grund der Anwendung",
    or: "Oder",
    andOr: "Und/Oder",
    selectedCrop: "Ernte auswählen",
    change: "Abbruch",
    completed: "Abgeschlossen",
    qrCode: "QR-Code scannen",
    start: "Anfang",
    save: "Save",
    saveAddNew: "Save + add new",
    ok: "Ok",
    cancel: "Cancel",
    pick: "Wählen Sie",
    measurement: "Messung",
    substrate: "Substrate",
    drain: "Drain",
    gift: "Gift",
    ditch: "Ditch",
    observation: "Beobachtung",
    waterAndFertilization: "Bewässerung und Düngung",
    logTask: "Registrierung von:",
    cultivationActivity: "Kulturmaßnahme",
    units: "Units",
    addPhoto: {
      label: "Foto hinzufügen",
      overzich: "Übersicht",
      enkelePlant: "Einzelpflanze",
      detail: "Detail",
      wortels: "Wurzeln",
      overzicht: "Overview",
    },
    photoType: "Fototyp",
    category: "Kategorie",
    whatMeasurement: "Registrierung von:",
    addPH: "pH-Messung hinzufügen",
    addLength: "Länge in cm hinzufügen",
    addDiameter: "Durchmesser in cm hinzufügen",
    addBuds: "Anzahl der Knospen hinzufügen",
    addWeight: "Add weight",
    addYield: "Add yield",
    diameter: "Durchmesser",
    numberOfBuds: "Anzahl der Knospen/Blüten",
    weight: "Weight",
    yield: "Yield",
    whatObservation: "Beobachtung:",
    defectPhenomenon: {
      label: "Mangelerscheinung",
      options: {
        bladvlekkenGeel: "Blattflecken - chlorotisch",
        bladvlekkenBruin: "Blattflecken - nekrotisch",
        bladrandGeel: "Blattrand - chlorotisch",
        BladrandBruin: "Blattrand - nekrotisch",
        krullendBlad: "Blattdeformationen",
        bladval: "Blattfall",
        knopval: "Knospenfall",
      },
    },
    leafObservation: "Leaf observation",
    defectPhenomenonIn: "Mangelerscheinung an:",
    youngLeaves: "Jungen Blättern",
    oldLeaves: "Alten Blättern",
    leafBurn: "Blattverbrennungen",
    flowering: "Flowering",
    fungus: {
      label: "Pilze und bakterielle Infektionen",
      options: {
        altenaria: "Altenaria",
        bladvlekkenziekte: "Blattfleckenkrankheit",
        bloemrot: "Blumenendfäule",
        botrytis: "Botrytis",
        didymella: "Didymella",
        fusarium: "Fusarium",
        japanseRoest: "Japanischer Rost",
        echteMeeldauw: "Echter Mehltau",
        valseMeeldauw: "Falscher Mehltau",
        phytophthora: "Phytophthora",
        pythium: "Pythium (Wurzelfäule)",
        roetdauw: "Ruß-/Taupilze",
        sclerotinia: "Sclerotinia",
        stengelbasisrot: "Stängelgrundfäule",
        verticillium: "Verticillium",
        zwarteVlekkenziekte: "Schwarzfleckenkrankheit",
        nofungus: "No fungus and bacterial disease",
      },
    },
    plague: {
      label: "Schädling",
      options: {
        aaltjes: "Nematoden",
        bladluizen: "Blattläuse",
        cicaden: "Zikaden",
        kevers: "Käfer",
        mineervliegen: "Minierfliegen",
        muggenVliegen: "Trauermücken",
        rupsen: "Lepidoptera (Falter)",
        mijten: "Spinnmilben",
        trips: "Thrips",
        wantsen: "Wanzen",
        mole: "Maulwürfe",
        otherInsects: "Weitere Insekten",
        mice: "Mäuse",
        symphyla: "Zwergfüßer",
        weeds: "Unkraut",
        slugs: "Schnecken",
        springtail: "Springschwänze",
        whiteFly: "Weiße Fliege",
        wolluizen: "Wollläuse",
        dopluizen: "Dopluizen",
        schildluizen: "Schildluizen",
        noplagues: "No pests detected",
      },
    },
    color: "Blattfarbe",
    failure: "Kulturfehler",
    rating: {
      label: "Rating",
      options: {
        germRate: "Germ rate",
        leafColor: "Leaf color",
        leafGloss: "Leaf gloss",
        shootFormation: "Shoot formation",
        flowerSize: "Flower size",
        flowerFirmness: "Flower firmness",
        floridity: "Floridity",
        rooting: "Rooting",
        uniformity: "Uniformity",
        moisture: "Moisture",
        branching: "Branching",
        plantFirmness: "Plant firmness",
        plantStructure: "Plant structure",
        compactness: "Compactness",
        cutlings: "Cutlings",
        growth: "Wachstum",
      },
    },
    intensity: {
      label: "Intensity",
      levels: [
        {
          value: 1,
          label: "Light",
        },
        {
          value: 2,
          label: "Some",
        },
        {
          value: 3,
          label: "Medium",
        },
        {
          value: 4,
          label: "A lot",
        },
        {
          value: 5,
          label: "Heavy",
        },
      ],
    },
    LeafTips: "Blattspitzen",
    selectDefect: "Mangelerscheinung auswählen",
    selectFungi: "Wähle eine(n) Pilz/Bakteriose",
    selectPlague: "Wähle einen Schädling",
    addEC: "EC-Wert Gießwasser",
    addECMeasurement: "Add EC measurement",
    addWaterMeasurement: "Gießdauer (Minuten)",
    whichFertilizationCategory: "Düngerart",
    cropControl: "Pflanzenschutz",
    whichCropControl: "Durchgeführte Pflanzenschutzmaßnahmen",
    misc: "Sonstiges",
    miscellaneous: "Verschiedenes",
    numberOfMeters: "Behandelte Fläche in m²:",
    concentration: "Konzentration (%):",
    photoUpload: "Foto hochladen",
    pruning: "Stutzen",
    budding: "Pinzieren",
    widening: "Rücken",
    sorting: "Sorting",
    moveCultivationBatch: "Satz verschieben",
    changePotsize: "Change container",
    rotate: "Rotate",
    cutting: "Cutting",
    cuttingLeaf: "Cutting leaf",
    layFoil: "Lay foil",
    removeFoil: "Remove foil",
    harvest: "Harvest",
    lower: "Lower",
    settle: "Settle",
    removeLeafs: "Remove leafs",
    bendIn: "Bend in",
    placeSticks: "Place sticks",
    siftingThrough: "Geisen",
    pollinate: "Pollinate",
    binding: "Binding",
    weeding: "Weeding",
    deflower: "Deflower",
    removeTendrils: "Remove tendrils",
    placeCatchCards: "Place catch cards",
    tendrils: "Tendrils",
    raiseTheGauze: "Raise the gauze",
    cropMaintenance: "Crop maintenance",
    removeBuds: "Remove buds",
    potting: "Topfen",
    deliverFinishedPlants: "Zustellen",
    pottingUnit: "plugs/cuttings",
    deliverFinishedPlantsUnit: "plants",
    floridityUnit: "average amount of flowers",
    sow: "Sau",
    makeCuttings: "Stecklinge machen",
    cuttingUnder: "Cutting under",
    cuttingRound: "Cutting round",
    technicalService: "Technischer Dienst",
    length: "Länge",
    chemicalProtection: {
      label: "Flüssigkeit",
      options: {
        batavia: "Batavia",
        boxer: "Boxer",
        butisanS: "Butisan S",
        bonzi: "Bonzi",
        configuresc: "Configure Sc",
        collis: "Collis",
        cecece: "CeCeCe",
        conserve: "Conserve",
        AZ500: "AZ 500",
        abamectineHf: "Abamectine HF",
        centurionplus: "Centurion Plus",
        carex10sc: "Carex 10 SC",
        decis: "Decis",
        dithane: "Dithane NeoTec",
        dimanin: "Dimanin",
        floramite: "Floramite",
        flint: "Flint",
        gnatrolSc: "Gnatrol SC",
        karateZeon: "Karate zeon",
        limocide: "Limocide",
        lunaPrivilige: "Luna Privilege",
        mirageElan: "Mirage Elan",
        miragePlus: "Mirage plus",
        movento: "Movento",
        nissorun: "Nissorun",
        ortiva: "Ortiva",
        ortivaTop: "Ortiva Top",
        paraat: "Paraat",
        previcurEnergy: "Previcur Energy",
        proplant: "Proplant",
        ranman: "Ranman",
        rovral: "Rovral",
        serenade: "Serenade",
        scelta: "Scelta",
        sivantoPrime: "Silvanto Prime",
        siltac: "Siltac",
        signum: "Signum",
        steward: "Spuitzwavel Microsulfo",
        sumicidinSuper: "Sumicidin Super",
        switch: "Switch",
        teldor: "Teldor",
        teppeki: "Teppeki",
        tracer: "Tracer",
        xentarie: "Xentarie",
        vertimec: "Vertimec",
        azatin: "Azatin",
        biosweet: "Biosweet",
        bitterzout: "Bitterzout",
        contans: "Contans",
        dipel: "Dipel",
        dolokal: "Dolokal",
        kumulusS: "Kumulus S",
        kumar: "Kumar",
        microsulfo: "Spuitzwavel Micro Sulfo",
        nectarMagnwsiumDuo: "Nectar magnesium duo",
        neemazal: "NeemAzal ",
        suitzwavel: "Zwavel",
        trianium: "Trianum",
        aCSzwavel800: "ACS- zwavel 800",
        admiral: "Admiral",
        alibiflora: "Alibi flora",
        altacor: "Altacor",
        applaud: "Applaud",
        asperello: "Asperello",
        attracker: "Attracker",
        bifasto: "Bifasto",
        biosoap: "Biosoap",
        botanigardWP: "Botanigard WP",
        captosan500sc: "Captosan 500 sc",
        closer: "Closer",
        dazideenhance: "Dazide enhance",
        ecomist: "Eco mist",
        ethrelA: "Ethrel A",
        fado: "Fado",
        falgro: "Falgro",
        flipper: "Flipper",
        frupica: "Frupica",
        fungaflash: "Fungaflash",
        gazelle: "Gazelle",
        geoxe: "Geoxe",
        hasten: "Hasten",
        hfOnkruidVrij: "HF Onkruid vrij",
        hiCure: "Hi Cure",
        hiwett: "Hi wett",
        inssimo: "Inssimo",
        karma: "Karma",
        mainspring: "Mainspring",
        MCPA500: "MCPA 500",
        nocturn: "Nocturn",
        nomolt: "Nomolt",
        oberon: "Oberon",
        oroganic: "Oroganic",
        pedimentrookontwikkelaar: "Pediment rookontwikkelaar",
        pirimor: "Pirimor",
        pironpushbox: "Piron pushbox",
        prolong: "Prolong",
        ranmanTop: "Ranman Top",
        rhizopon: "Rhizopon",
        rocket: "Rocket",
        rodilinHavermix: "Rodilin Havermix",
        rodilonsoftblock: "Rodilon soft block",
        ridomilGold: "Ridomil Gold",
        rizolex: "Rizolex",
        runner: "Runner",
        saponin: "Saponin",
        score250EC: "Score250EC",
        silwetgold: "Silwet gold",
        sporgon: "Sporgon",
        sluxx: "Sluxx",
        sonata: "Sonata",
        suikerlokstof: "Suikerlokstof",
        tzwavel: "T- zwavel",
        taegro: "Taegro",
        takumi: "Takumi",
        tomCatRTU: "Tom Cat RTU",
        topaz: "Topaz",
        topsinmutra: "Topsin M utra",
        turexPowder: "Turex Poeder",
        vacciplant: "Vacciplant",
        valiosoSt: "Violoso-ST",
        vivando: "Vivando",
        abir: "Abir",
        mycotal: "Mycotal",
        motto: "Motto",
        meltatox: "Meltatox",
        winner: "Winner",
        fusiladeMax: "Fusilade Max",
        wingP: "Wing P",
        agralGold: "Agral Gold",
        goltixWG: "Goltix WG",
        roundupDynamic: "Roundup Dynamic",
        roundupEvolution: "Roundup Evolution",
        concreteHF: "Concrete HF",
        corzalSE: "Corzal SE",
        calciumShuttle: "Calcium Shuttle",
        basfoliar36Extra: "Basfoliar 36 Extra",
        calciumChoride: "Calcium Choride",
        ridemilGold: "Ridemil Gold",
        pergado: "Pergado",
        abemectineHF: "Abemectine HF",
        captosan500Sc: "Captosan 500 sc",
        helioterpen: "Helioterpen",
        thripsLok: "ThripsLok",
        fulvic: "Fulvic",
      },
    },
    addChemicalProtection: "Wähle ein Produkt",
    addOrganicProtection: "Wähle ein Produkt",
    organicProtection: {
      label: "Streugut (Biologischer Pflanzenschutz)",
      options: {
        adaliaBipunctata: "Adalia bipunctata",
        amblydromalusLimonicus: "Amblydromalus limonicus",
        amblyseiusSwirskii: "Amblyseius swirskii",
        aphelinusAbdomalis: "Aphelinus abdomalis",
        aphidiusColemani: "Aphidius colemani",
        aphidiusErvi: "Aphidius ervi",
        aphidoletesAphidimyza: "Aphidoletes aphidimyza",
        azatin: "Azatin",
        biosweet: "Biosweet",
        bitterzout: "BitterSalz",
        chrysoperlaCarnea: "Chrysoperla carnea",
        contans: "Contans",
        dacnusaSibirica: "Dacnusa sibirica",
        diglyphusIsaea: "Diglyphus isaea",
        dipel: "Dipel",
        dolokal: "Dolokal",
        encarsiaFormosa: "Encarsia formosa",
        eretmocerusEremicus: "Eretmocerus eremicus",
        feltiellaAcarisuga: "Feltiella acarisuga",
        hypoaspisMiles: "Hypoaspis miles",
        kumulusS: "Kumulus S",
        macrochelesRobustulus: "Macrocheles robustulus",
        macrolophusPygameus: "Macrolophus pygameus",
        microsulfo: "Microsulfo",
        nectarMagnwsiumDuo: "Nectar magnesium duo",
        neemazal: "NeemAzal",
        neoseiulusCalifornicus: "Neoseiulus californicus",
        neoseiulusCucumeris: "Neoseiulus cucumeris",
        oriusLaevigatus: "Orius laevigatus",
        phytoseiulusPersimilis: "Phytoseiulus persimilis",
        suitzwavel: "Elementarer Schwefel",
        steinernemaCarpocapsae: "Steinernema carpocapsae",
        steinernemaFeltiae: "Steinernema feltiae",
        transeiusMontdorensis: "Transeius montdorensis",
        trianium: "Trianum",
        trichogrammaArchaeae: "Trichogramma archaeae",
        anagyrusVladimiri: "Anagyrus vladimiri",
        aphidiusMatricariae: "Aphidius matricariae",
        pekelkreeftcystenArtemiaspp: "Pekelkreeftcysten artemia spp",
        athetaCoriaria: "Atheta coriaria",
        bombusTerrestris: "Bombus terrestris (Hummeln)",
        cryptolaemusMontrouzieri: "Cryptolaemus montrouzieri",
        delphastusCatalinae: "Delphastus catalinae",
        enermix: "Enermix",
        ephestiaKuehniella: "Ephestia kuehniella",
        carpoglyphusLactis: "Carpoglyphus lactis",
        AZ500: "AZ 500",
        hollandFytozide: "holland Fytozide",
        Teppeki: "Teppeki",
        springbok: "Springbok",
      },
    },
    PPP: "Plant protection product",
    biocide: "Biocide",
    adjuvant: "Adjuvant",
    addgrowthRegulation: "Wähle ein Produkt",
    addMeasurement: "Messung hinzufügen",
    remmen: {
      label: "Hemmstoffeinsatz",
      options: {
        alar: "Alar",
        bonzi: "Bonzi",
        calciumchloride: "Calcium chloride",
        chryzopon: "Chryzopon",
        controlGrowdesign: "Growdesign",
        cycocel: "Cycocel",
        dazide: "Dazide",
        elastoG5: "Elasto G5",
        koper: "Kupfer",
        magnesiumchloride: "Magnesium chlorid",
        pirouette: "Pirouette",
        regalis: "Regalis",
        rhizoponAa: "Rhizopon AA",
        uplccc750: "Upl ccc 750",
        questuran: "Questuran",
      },
    },
    addRemmen: "Wähle ein Produkt",
    floweringTreatment: {
      label: "Blütenbehandlung",
      options: {
        auxine: "Auxine",
        bonzi: "Bonzi",
        carousel: "Carousel",
        configureSc: "Configure SC",
        ethylene: "Ethylen",
        florgib: "Florgib",
        gibbereline: "Gibbereline",
        valiosoSt: "Valioso ST",
      },
    },
    addFloweringTreatment: "Wähle ein Produkt",
    addNote: "Notiz hinzufügen",
    filters: { title: "Filters", gotoLevel2: "Go to level 2" },
    edit: "Edit",
    reset: "Reset",
    uploadPhoto: "Foto",
    whatCrop: "Zu welchem Anbau gehört die Notiz?",
    whatToDo: "Was würdest du gern tun?",
    whatTask: "Welche Aufgabe haben Sie ausgeführt?",
    whichRegulator: "Welchen Wachstumsregulator haben Sie verwendet?",
    whatObserve: "Ergänzung:",
    howMiliter: "Wie viel Milliliter?",
    notAdded: "Erfolg! Möchten Sie noch etwas hinzufügen?",
    yes: "Ja",
    no: "Nein",
    both: "Beides",
    amDone: "Nein, ich bin fertig!",
    yourNote: "Ihre Notiz...",
    yourOwn: "Your own...",
    skip: "Überspringen",
    skipQuestion: "Skip question",
    tankage: {
      label: "Tankvolumen",
      concentration: "Fläche in m²:",
      tankage: "Tankvolumen in L:",
    },
    productAmount: {
      label: "Mittelmenge",
      concentrationTotal: "Konzentration des Produkts",
      concentrationTotalKg: "Konzentration in kg / Ha",
      numberOf: "Ausgebrachte Verpackungseinheiten",
      pieces: "Nützlinge pro Verpackungseinheit",
      totalApplied: "Gesamte Nützliperngsanzahl",
      tankageTotal: "Menge der Sprühlösung (L):",
      tankageTotalKg: "Mittelmenge gesamt (kg)",
      amountAgent: "Absolute Mittelmenge",
      totalAppliedFertilizer: "Insgesamt aufgetragener Dünger",
      kgPer: "Kg pro Einheit",
    },
    product: "Product",
    tankageMix: "Product {idx} of {total}",
    fertilization: {
      label: "Düngung",
      spreadible: {
        label: "Gestreuter Dünger",
        title: "Welcher Dünger wurde gestreut?",
        options: {
          dcmMix1: "DCM mix 1",
          dcmMix2: "DCM mix 2",
          dcmMix3: "DCM mix 3",
          dcmMix4: "DCM mix 4",
          dcmMix5: "DCM mix 5",
          dcmMix6: "DCM mix 6",
          vivikali: "Vivikali",
          dolokal: "Dolokal",
          lokdoosMetGif: "Lokdoos met gif",
          lokdoosZonderGif: "Lokdoos zonder gif",
          kalkalmmonsalpeter: "Kalkalkmmonsalpeter",
          magnesamonsalpeter: "Magnesamonsalpeter",
          patentkali: "Patentkali",
          protixflytilizer: "Protix Flytilizer",
          innogreenecogroei: "Innogreen ECO groei",
          tripelsuperfosfaat: "Tripelsuperfosfaat",
        },
      },
      foliar: {
        label: "Blattdünger",
        title: "Welcher Blattdünger?",
        options: {
          cuprum: "Cuprum",
          calciumchloride: "Calcium chloride",
          salifort: "Salifort",
          siliforce: "Siliforce",
          soluplantSF31661414CaO: "Soluplant SF3 16-6-14+14CaO",
          olfAg: "OLF Ag",
          espotop: "Bitterzout Epso Top",
          bitterzout: "Bitterzout",
          fertigroMSN: "Fertigro MSN",
          fertigroCN: "Fertigro CN",
          zwavelzuur37: "Zwavelzuur 37%",
          powerP: "Power P",
          superFK: "Super FK",
          universolgroen: "Universol Groen 23-6-10",
          kalksalpeter: "Kalksalpeter",
          kalksalpeterCalcinite: "Kalksalpeter Calcinite",
          kalksalpeterTropicote: "Kalksalpeter Tropicote",
          calsal: "Calsal Substrafeed Yara",
          yaraNitromag: "YaraBela Nitromag 27+4 MgO",
          spuitzwavel: "spuitzwavel",
          yaraSulfan: "YaraBela Sulfan 24+18SO3",
          ureumprills: "Ureum Prills",
          HuwaSan: "Huwa-San TR-50",
          vitalance: "Vitalance",
        },
      },
    },
    causesCropcontrol: {
      options: {
        aaltjes: "Aaltjes",
        altenaria: "Altenaria",
        bacterienov: "bakterien",
        bladvlekkenziekte: "Blattfleckenkrankheit",
        beworteling: "Verwurzelung",
        bloemrot: "Blumenendfäule",
        botrytis: "Botrytis",
        bladluizen: "Läuse",
        bloeibehandeling: "Blütenbehandlung",
        cicaden: "Cicaden",
        echteMeeldauw: "Echter Mehltau",
        erwinia: "Erwinia",
        valseMeeldauw: "Falscher Mehltau",
        kevers: "Käfer",
        mineervliegen: "Minierfliege",
        muggenVliegen: "Trauermücken",
        rupsen: "Lepidoptera (Falter)",
        mijten: "Spinnmilben",
        trips: "Thrips",
        wantsen: "Wanzen",
        wolluizen: "Weiße Fliege",
        didymella: "Didymella",
        fusarium: "Fusarium",
        japanseRoest: "Japanischer Rost",
        phytophthora: "Phytophthora",
        pythium: "Pythium",
        roetdauw: "Ruß-/Taupilze",
        sclerotinia: "Sclerotinia",
        stengelbasisrot: "Stängelgrundfäule",
        verticillium: "Verticillium",
        zwarteVlekkenziekte: "Schwarzfleckenkrankheit",
        otherBacteria: "andere Bakterien",
        rooting: "Verwurzelung",
        leafSpot: "Blattfleck",
        floweringTreatment: "Blütenbehandlung",
        centerRot: "Herzfäule",
        adhesive: "Klebstoff",
        otherInsects: "Weitere Insekten",
        tuberRot: "Knollenfäule",
        attractant: "Lockstoff",
        aphids: "Läuse",
        mole: "Maulwürfe",
        mice: "Mäuse",
        shoreFly: "Uferfliege",
        weeds: "Unkraut",
        disinfection: "Desinfektion",
        misk: "Weitere",
        plantResistance: "Abhärtung",
        rats: "Ratten",
        remmen: "Wachstumsregulierung",
        rhizoctonia: "Rhizoctonia",
        caterpillars: "Raupen",
        otherFungus: "Weitere Pilze",
        slugs: "Schnecken",
        spiders: "Spinnmilben",
        springtail: "Springschwänze",
        sugarRot: "Ru-/Taupilze",
        whiteFly: "Weiße Fliege",
        rootRot: "Wuzelfäule",
        symphyla: "Zwergfüßer",
        tensides: "Netzmittel",
        dopluizen: "Dopluizen",
        schildluizen: "Schildluizen",
        branchMortality: "Branch mortality",
        beetles: "Beetles",
      },
    },
    back: "Zurück",
    next: "Nächster",
    done: "Done",
    pleaseSelectCrops: "Please select crops before proceeding.",
    backgroundSaveInProgress:
      "Some data is still being saved in the background...",
    pleaseWaitbackgroundSave: "Please wait until data saving has completed.",
    document: "Document",
  },
  resetPassword: {
    title: "Passwort zurücksetzen",
    message:
      "Geben Sie die E-Mail-Adresse Ihres Benutzerkontos ein und wir senden Ihnen einen Link zum Zurücksetzen des Passworts.",
    placeholder: "Geben sie ihre E-Mailadresse ein",
    send: "E-Mail zum Zurücksetzen des Passworts senden",
    successMessage: "Ein Link wurde gesendet!",
    emailNotUsed: "Diese E-Mail wird nicht verwendet!",
    updateSuccessMessage: "Passwort aktualisiert!",
  },
  footer: { disclaimer: "Disclaimer", contact: "Kontakt", faq: "FAQ" },
  timeControl: {
    selectRange: "Zeitbereich auswählen",
    last: "Last",
    deltas: {
      hour: "hour",
      day: "day",
      week: "week",
      month: "month",
      year: "year",
    },
  },
  notesFeed: {
    log: "Log",
    dailySummary: "Daily summary",
    fertilizationEc: "EC during watering",
    EC: "EC",
    "fertilization-Water": "Bewässerung",
    amountSpreadable: "Düngermenge",
    concentrationSpreadable: "Konzentration",
    "fertilization-fertilization": "Blattdüngung",
    spreadible: "Spreadible fertilization",
    foliar: "Foliar fertilization",
    amountAgent: "Mittelmenge",
    ec: "EC",
    ph: "pH",
    floweringTreatment: "Blütenbehandlung",
    remmen: "Hemmstoff",
    color: "Blattfarbe",
    length: "Länge",
    diameter: "Durchmesser",
    water: "Bewässerungsdauer",
    numberOfBuds: "Anzahl der Knospen/Blüten",
    weight: "Weight",
    yield: "Yield",
    surface: "Oberfläche",
    size: "Tankinhalt",
    concentration: "Konzentration",
    amount: "Menge",
    labData: "Laborwerte",
    compare: "Vergleichen",
    chemicalProtection: "Chemischer Pflanzenschutz",
    organicProtection: "Biologischer Pflanzenschutz",
    edit: "Edit",
    download: "Download",
    cancelEdit: "Bearbeitung beenden",
    toggleView: "Toggle view",
    successfullyDeleted: "Notizen erfolgreich gelöscht",
    remove: "Entfernen",
    note: "Notiz",
    notes: "Notizen",
    leafObservation: "Leaf observation",
    defectPhenomenon: "Blattrand - chlorotisch",
    leafBurn: "Blattverbrennungen",
    flowering: "Flowering",
    intensity: "Intensity",
    counting: "Counting",
    fungus: "Pilzliche Erkrankung",
    plague: "Tierischer Schaderreger",
    failure: "Kulturfehler",
    failureRate: "Failure rate",
    rating: "Rating",
    ratingValue: "Rating",
    LeafTips: "Blattspitzen",
    miscellaneous: "Verschiedenes",
    misc: "Verschiedenes",
    pieces: "Stücke pro Einheit",
    numberOf: "Anzahl der Einheiten",
    totalApplied: "Insgesamt angewendet",
    kgPer: "kg pro Einheit",
    kgPerUnit: "kg pro Einheit",
    totalIrrigation: "Total irrigation",
    floridity: "Floridity",
    units: {
      pieces: "pieces",
      "pieces / m2": "pieces / m2",
      "ml / 100 liters": "ml / 100 liters",
      "gram / 100 liters": "gram / 100 liters",
      kg: "kg",
      gr: "gram",
      units: "units",
    },
    formBranches: [
      { id: "all", label: "Show all" },
      { id: "favorites", label: "Favorites" },
      { id: "cultivationActivity", label: "Kulturmaßnahme" },
      { id: "measurement", label: "Messung" },
      { id: "observation", label: "Beobachtung" },
      { id: "waterAndFertilization", label: "Bewässerung und Düngung" },
      { id: "cropControl", label: "Pflanzenschutz" },
      { id: "addPhoto", label: "Fotos" },
      { id: "misc", label: "Andere" },
      { id: "misc", label: "Misc" },
      { id: "hasPhoto", label: "Picture mode" },
    ],
    addPhoto: "Foto",
    addFavorite: "Zu Favoriten hinzufügen",
    removeFavorite: "Aus Favoriten entfernen",
    labResults: "Laborergebnisse",
    started: "Kulturstart",
    substrate: "Substrate",
    drain: "Drain",
    gift: "Gift",
    ditch: "Ditch",
    changed: "Changed",
    from: "from",
    to: "to",
    added: "Added",
    removed: "Removed",
    containerTypes: {
      container: "container",
      tray: "tray",
      potSize: "potsize",
      plugType: "plug type",
      growcoon: "growcoon",
    },
    potSizeChange: "Changed pot size from",
    potSizeChangeTo: "to",
    locationChange: "Changed location from",
    locationChangeTo: "to",
    tankage: "Tankage",
    numberOfUnits: "Units applied",
    print: {
      companyName: "Company name",
      label: "Label",
      genus: "Genus",
      species: "Species",
      cultivar: "Cultivar",
      startWeek: "Start week",
      potSize: "Pot size",
      labId: "L&S ID",
      startingAmount: "Starting amount",
      currentAmount: "Current amount",
      businessLocation: "Business location",
      level1Locations: "Level 1 locations",
      level2Locations: "Level 2 locations",
      cropOverview: "Crop overview",
      referenceNumber: "Reference number",
      photoReference: "Photo reference",
      photosOverview: "Photos overview",
      log: "Log",
      summary: "Summary",
      at: "at",
      by: "by",
      batchNumber: "Batch number",
      youngPlantSuppliers: "Young plant suppliers",
      sizeDescription: "Size description",
      supplierDocumentNr: "Supplier document nr.",
      containerNr: "Container number",
      substrateMixture: "Substrate mixture",
      fertilization: "Fertilization",
      barcode: "Barcode",
      weeknumber: "Week number",
      potDate: "Pot date",
      endDate: "End date",
      spacingDate: "Arrival date of young plant material",
      description: "Description",
      objectives: "Objectives and challenges for this crop",
      container: "Container",
      openField: "Open field",
      tray: "Tray",
      plugType: "Plug type",
    },
    startWeek: "Start week",
    week: "Week",
    weather: "Weather",
    confirmDelete: "Are you sure you want to delete this note?",
    confirmDeleteMultiple:
      "Are you sure you want to delete this note across all crops?",
    confirmEditMultiple:
      "Are you sure you want to edit this note across all crops?",
    confirmEdit: "Are you sure you want to edit this note?",
    delete: "Delete",
    cancel: "Cancel",
    update: "Update",
    show: "Show",
    moreNotesInBatch: "more notes in batch",
    collapse: "Collapse",
  },
  cropCard: {
    startDate: "STARTWOCHE",
    expectedDate: "ZIELDATUM",
    endDate: "END DATE",
    location: "STANDORT",
    potSize: "TOPFGRÖßE",
    tray: "TRAY",
    growcoon: "GROWCOON",
    plugType: "PLUG TYPE",
    openField: "OPEN FIELD",
    labId: "LAB ID",
    changePhoto: "CHANGE PHOTO",
    editBatch: "Info ändern",
    week: "KW",
  },
  compareLogs: {
    compareLogs: "Kulturen vergelichen",
    selectCrop: {
      1: "Erste Kulutr:",
      2: "Zweite Kultur:",
      3: "Dritte Kultur:",
    },
    add: "Hinzufügen",
    useLandscape: "Add Landscape",
    noData: "Keine Daten",
    selectCrops: "Kultur wählen..",
    reminderModal: {
      title: "Erinnerung hinzufügen",
      datePickerPlaceholder: "Datum wählen",
      textareadPlaceholder: "Beschreibung Erinnerung...",
      button: "Save",
      reminderAddedSuccessfully: "Neue Erinnerung hinzugefügt!",
      reminderAddedNotSuccessfully: "Keine neue Erinnerung hinzugefügt!",
    },
  },
  task: {
    title: {
      create: "Aufgabe erstellen",
      edit: "Edit",
      list: "List of Tasks",
      mytasks: "Aufgaben",
    },
    type: {
      label: "Art der Aufgabe",
      defaultDescription: {
        pruning: "Satz Stutzen",
        pinching: "Statz pinzieren",
        spacing: "Pflanzen rücken",
        move: "Satz verschieben",
        sorting: "Partei sortieren",
        changePotsize: "Topfgröße ändern",
        rotate: "Drehen",
        cuttingLeaf: "Blatt schneiden",
        layFoil: "Lay foil",
        removeFoil: "Remove foil",
        harvest: "Ernten Sie die Produktion",
        lower: "Niedere Pflanzen",
        settle: "Fix",
        removeLeafs: "Remove leafs",
        bendIn: "Bend in",
        placeSticks: "Place sticks",
        siftingThrough: "Sifting through",
        pollinate: "Pollinate",
        binding: "Binding",
        weeding: "Weeding",
        deflower: "Deflower",
        removeTendrils: "Remove tendrils",
        placeCatchCards: "Place catch cards",
        tendrils: "Tendrils",
        raiseTheGauze: "Raise the gauze",
        cropMaintenance: "Crop maintenance",
        removeBuds: "Remove buds",
        potting: "Topfen",
        deliverFinishedPlants: "Zustellen",
        sow: "Sau",
        makeCuttings: "Stecklinge machen",
        technicalService: "Technical service",
        PPP: "Plant protection product",
        biocide: "Biocide",
        adjuvant: "Adjuvant",
        liquidCropControl: "Pflanzenschutz spritzen",
        spreadingCropControl: "Pflanzenschutz streuen",
        growthRegulation: "Hemmstoff ausbringen",
        floweringTreatment: "Blütenbehandlung durchführen",
        controlRound: "Kontrollrunde: Messungen, Fotoaufnahmen, Scouting",
        spreadableFertilizers: "Granulierten Dünger streuen",
        foliarFertilizers: "Blattdüngung",
        scouting:
          "Bewertung der Pflanzengesundheit (Schädlinge, Mangelsymptome, pilzliche Erreger und Bakteriosen",
        misc: "Miscellaneous",
        checkliste: "Checkliste",
      },
      name: {
        pruning: "Pruning",
        pinching: "Pinching",
        spacing: "Spacing",
        move: "Move",
        sorting: "Sortieren",
        changePotsize: "Topfgröße ändern",
        rotate: "Drehen",
        cuttingLeaf: "Blatt schneiden",
        layFoil: "Lay foil",
        removeFoil: "Remove foil",
        harvest: "Ernten",
        lower: "Niedere",
        settle: "Fix",
        removeLeafs: "Remove leafs",
        bendIn: "Bend in",
        placeSticks: "Place sticks",
        siftingThrough: "Sifting through",
        pollinate: "Pollinate",
        binding: "Binding",
        weeding: "Weeding",
        deflower: "Deflower",
        removeTendrils: "Remove tendrils",
        placeCatchCards: "Place catch cards",
        tendrils: "Tendrils",
        raiseTheGauze: "Raise the gauze",
        cropMaintenance: "Crop maintenance",
        removeBuds: "Remove buds",
        potting: "Topfen",
        deliverFinishedPlants: "Zustellen",
        sow: "Sau",
        makeCuttings: "Stecklinge machen",
        technicalService: "Technical service",
        PPP: "Plant protection product",
        biocide: "Biocide",
        adjuvant: "Adjuvant",
        liquidCropControl: "Liquid crop control",
        spreadingCropControl: "Spreading crop control",
        growthRegulation: "Growth regulation",
        floweringTreatment: "Flowering treatment",
        controlRound: "Control round",
        spreadableFertilizers: "Spreadable fertilizers",
        foliarFertilizers: "Foliar fertilizers",
        scouting: "Scouting",
        misc: "Misc",
        checkliste: "Checkliste",
      },
    },
    checklist: { label: "Checklist" },
    selectCrop: "Pflanze auswählen",
    openField: "Open field",
    selectLocation: { label: "Standort wählen", placeholder: "Pick locations" },
    description: { label: "Beschreibung", placeholder: "Beschreibung" },
    user: { label: "Verantwortlicher", noUser: "No user" },
    date: "Date",
    completed: "Completed",
    repeats: {
      label: "Anzahl Wiederholungen",
      help: "Die Anzahl der Wiederholungen dieser Aufgabe",
    },
    repeatWeekOffset: {
      label: "Wiederholung alle ... Wochen",
      help: "Die Anzahl der Wochen zwischen den Wiederholungen. Wählen Sie 1 für sich wöchentlich wiederholende Aufgaben, 2 für zweiwöchentliche usw.",
    },
    deadline: "Zieldatum",
    startDate: "Start-Datum",
    isRecurringTask: "Wiederkehrendes Ereignis",
    button: {
      create: "Erstellen",
      update: "Update",
      delete: "Delete",
      addTask: "Aufgabe hinzufügen",
      allTasks: "All Tasks",
    },
    filter: {
      dueToday: "Heute fällig",
      thisWeek: "Dieser Woche",
      showAll: "Zeige alles",
    },
    more: "Mehr",
    delete: "Delete task",
    edit: "Edit task",
    confirmDelete: "Are you sure you want to delete this task?",
    column: {
      name: "Cultivation name",
      businessLocation: "Business location",
      level1Location: "Level 1 location",
      level2Location: "Level 2 location",
      batchNumber: "Batch number",
      potDate: "Pot date",
      potSize: "Potsize",
      startingAmount: "Starting amount",
      currentAmount: "Current amount",
      surface: "Surface",
    },
    addNoteToSelected: "Add note to selected",
    markSelectedAsDone: "Mark selected as done",
    performBy: "Perform by",
    crops: "crops",
    crop: "crop",
    assignedTo: "Assigned to:",
    createdBy: "Created by:",
    you: "you",
    task: "Task",
    protocol: "Protocol",
    createdByType: {
      user: "User",
      protocol: "Protocol",
      all: "All",
    },
    taskDetails: "Task details",
    details: "Details",
    totalOf: "Total of selected crops",
    finished: "Finished",
    showCompletedOnly: "Show completed only",
  },
  alerts: {
    markAllAsRead: "Alles als gelesen markieren",
    noAlerts: "Alle aufgeholt!",
  },
  pdfmake: {
    title: "PDF generieren",
    regeneratePdf: "Regenerieren",
    downloadPdf: "Download",
  },
  vuelidate: {
    unknownLabel: "Dieses Feld",
    errors: {
      required: "{label} Ist ein Pflichtfeld.",
      minLength: "{label} Haben müssen {min} oder mehr Elemente.",
      maxLength: "{label} Kann max {max} elemente.",
      minValue: "{label} Kuss größer sein als {min}.",
      maxValue: "{label} Muss kleiner sein als {max}.",
      email: "{label} Muss eine gültige E-Mail-Adresse sein.",
      phoneNumber: "{label} Muss eine gültige Telefonnummer sein.",
      sameAsPassword: "{label} müssen abgeglichen werden.",
      unknownError: "{label} hat einen ungültigen Wert.",
    },
  },
  companyLabels: {
    consumptionCrops: {
      title: "Consumption Crops",
      options: {
        largeFruit: "Große Frucht",
        smallFruit: "Kleine Frucht",
        leafVegetables: "Blattgemüse",
        legumes: "Hülsenfrüchte",
        fruitVegetables: "Fruchtgemüse",
        cabbageCrops: "Kohlkulturen",
        rootAndTuberVegetables: "Wurzel und Knollengemüse",
        stalkVegetables: "Stalk vegetables",
        herbs: "Kräuter",
        edibleMushrooms: "Essbare Pilze",
      },
    },
    ornamentals: {
      title: "Ornamentals",
      options: {
        flowerBulbsTubers: "Flower bulbs and flower tubers",
        bulbFlowersTubers: "Bulb flowers and tubers",
        pottedPlants: "Potted plants",
        cutFlowers: "Cut flowers",
        draftShrubs: "Draft shrubs",
        cutGreen: "Cut green",
        beddingPlants: "Bedding plants",
        treeNursery: "Tree nursery",
        growingPerennials: "Growing perennials",
        flowerSeedCultivation: "Flower seed cultivation",
        swampWaterPlants: "Swamp and water plants",
        breedingBasicSeedProduction: "Breeding and basic seed production",
        temporarilyUncultivatedTerrain: "Temporary uncultivated terrain",
        permanentlyUncultivatedTerrain: "Permanently uncultivated terrain",
      },
    },
    cultivationMethod: {
      title: "Anbaumethode",
      options: {
        biological: "Biologisch",
        regular: "Regulär",
        openField: "Offenes feld",
        substrateBased: "Substratbasiert",
        hydroponic: "hydroponisch",
        verticalFarming: "Vertikale Landwirtschaft",
      },
    },
    countries: {
      title: "Länder",
      options: {
        nl: "The Netherlands",
        be: "Belgium",
        de: "Germany",
        uk: "United Kingdom",
        fr: "France",
        na: "North America",
        sa: "South America",
        as: "Asia",
        es: "Spain",
        pl: "Poland",
        ch: "Switzerland",
        dk: "Denmark",
        pt: "Portugal",
      },
    },
  },
  common: { of: "of" },
  updateEmail: {
    intro: "Your clients dashboards have been updated",
    dear: "Dear",
    new: "New",
    addedTo: "added to",
    subject: "Your daily summary",
    notes: "notes",
    photos: "photos",
    labSamples: "lab data",
    labSampleComments: "lab data comments",
    greetings: "Best regards",
    unsub: "Unsubscribe from daily update",
  },
  protocol: {
    title: {
      myProtocols: "Protocols",
      applyProtocol: "Apply protocol",
      create: "Create a protocol",
      potSizes: "Pot Sizes:",
      protocolName: "Protocol:",
      kpis: "Kpi's:",
      week: "Week",
      stage: "Stage:",
      startDate: "Start date:",
      any: "Any",
      unnamed: "Unnamed",
      protocol: "Protocol",
      tank: "Tank",
      action: "Action",
      alert: "Alert",
      protocolsOverview: "Protocols overview",
      trays: "Trays:",
      openField: "Open field:",
    },
    alerts: {
      added: "New protocol added!",
      updated: "Protocol updated successfully!",
      deleted: "Protocol deleted successfully!",
      error: "Something went wrong!",
    },
    button: {
      add: "Add protocol",
      duplicateFrom: "Duplicate from",
      next: "Next",
      back: "Back",
      addStage: "Add stage",
      addFirstStage: "Add your first stage",
      removeStage: "Remove stage",
      addKpi: "Add KPI",
      addFirstKpi: "Add your first KPI",
      removeKpi: "Remove KPI",
      addAction: "Add action",
      addFirstAction: "Add your first action",
      removeAction: "Remove action",
      addAlert: "Add alert",
      addFirstAlert: "Add your first alert",
      removeAlert: "Remove alert",
      cancel: "Cancel",
      save: "Save",
      edit: "Edit",
      clone: "Clone",
      update: "Update",
      delete: "Delete",
      select: "Select",
      addCondition: "Add condition",
      addFirstCondition: "Add your first condition",
      addFertilizationSchema: "Add fertilization schema",
      addFirstFertilizationSchema: "Add your first fertilization schema",
      addClimateSchema: "Add climate schema",
      addFirstClimateSchema: "Add your first climate schema",
      addWeatherData: "Add weather data",
      addTank: "Add tank",
      addStartDates: "Add start dates",
      goToTask: "Go to task",
    },
    add: {
      stage: {
        type: "Type",
        stages: "Stages",
        kpis: "KPI's",
        actions: "Actions",
        alerts: "Alerts",
        conditions: "Cultivation conditions",
        generalConditions: "General cultivation conditions",
        fertilizationSchematics: "Fertilization schematics",
        climateSchema: "Climate schema",
      },
      label: {
        genus: "Genus",
        species: "Species",
        cultivar: "Cultivar",
        potSize: "Pot size",
        tray: "Tray",
        openField: "Open field",
        startDate: "Start date between",
        stageName: { stage: "Stage", name: "name" },
        value: "Value",
        week: "Week",
        from: "From",
        until: "Until",
        fromWeek: "From week",
        untilWeek: "Until week",
        kpi: "KPI",
        selectKpi: "Select KPI",
        number: "Number",
        units: "Units",
        actionType: "Action type",
        selectType: "Select type",
        description: "Description",
        observationType: "Observation type",
        protocolsName: "Protocol's name",
        name: "Name",
        min: "Min",
        max: "Max",
        conditionType: "Condition type",
        fertilizationSchema: "Fertilization schema",
        climateSchema: "Climate schema",
        recurringAction: "Recurring",
        repeatWeekOffset: "Repeat week offset",
        repeats: "Repeats count",
        endStartDate: "End of start date",
        enabled: "Enabled",
      },
    },
    kpi: [
      {
        id: "length",
        name: "Length",
        units: [
          { id: "cm", name: "cm" },
          { id: "inch", name: "inch" },
          { id: "m", name: "m" },
          { id: "mm", name: "mm" },
          { id: "ft", name: "ft" },
        ],
      },
      {
        id: "width",
        name: "Width",
        units: [
          { id: "cm", name: "cm" },
          { id: "inch", name: "inch" },
          { id: "m", name: "m" },
          { id: "mm", name: "mm" },
          { id: "ft", name: "ft" },
        ],
      },
      {
        id: "numberOfFlowers",
        name: "Number of flowers",
        units: [{ id: "pieces", name: "pieces" }],
      },
    ],
    overview: {
      dateActive: "Date active",
      cropNameDescription: "Crop name / description",
      locations: "Locations",
      batch: "Batch number",
      potweek: "Potweek",
      potSize: "Potsize",
      plantNumber: "Plant number",
      dates: "Dates",
    },
    condition: [
      { id: "potting_planting", name: "Potting/Planting" },
      { id: "substrate", name: "Substrate" },
      { id: "starting_material", name: "Starting material" },
      { id: "fertilization", name: "Fertilization" },
      { id: "rooting", name: "Rooting" },
      { id: "irrigation_water_ec", name: "Irrigation water EC" },
    ],
    annotation: {
      crop: "Crop annotation",
      stage: "Stages annotation",
      kpi: "KPI's annotation",
      action: "Actions annotation",
      alert: "Alerts annotation",
      conditionSchema: "General conditions annotation",
      fertSchema: "Fertilization schemes annotation",
      climateSchema: "Climate schemes annotation",
      notApplied: "Protocol is not applied.",
      noAvailableProtocols: "No protocols available for this crop.",
      considerCreating: ["Consider", "creating a new protocol"],
      applyProtocol: "Apply a protocol",
    },
  },
  agreement: {
    weValueYourPrivacy: "We value your privacy",
    dataSafety:
      "Your data is safe with us. All data is stored on AVG compliant servers. We do not sell your data to external parties. We do not store data that we do not need. But we would like to keep improving our software services. Want to know more? Read the privacy statement or send us an email.",
    read: "Read terms and conditions",
  },
};
